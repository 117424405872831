export const soloInitialValues = {
  name: '',
  email: '',
  phone: '',
  nationality: '',
  gender: 'male',
  docType: 'docTypeNational',
  docNumber: '',
  dateOfBirth: '',
  address: '',
  bicycle: '',
  wayOfTravel: '',
  bikepackingCompletedRaces: 0,
  favoriteSong: '',
  user_captcha_input: '',
  termsAndConditionsAccepted: false,
};

export const pairInitialValues = {
  name: '',
  email: '',
  phone: '',
  nationality: '',
  gender: 'male',
  docType: 'docTypeNational',
  docNumber: '',
  dateOfBirth: '',
  address: '',
  bicycle: '',
  wayOfTravel: '',
  bikepackingCompletedRaces: 0,
  favoriteSong: '',
  fellowName: '',
  fellowEmail: '',
  fellowPhone: '',
  fellowNationality: '',
  fellowGender: 'male',
  fellowDocType: 'docTypeNational',
  fellowDocNumber: '',
  fellowDateOfBirth: '',
  fellowAddress: '',
  fellowBicycle: '',
  fellowWayOfTravel: '',
  fellowBikepackingCompletedRaces: 0,
  fellowFavoriteSong: '',
  user_captcha_input: '',
  termsAndConditionsAccepted: false,
};
