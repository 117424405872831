import styled from 'styled-components';
import classNames from 'classnames';

import { Grid } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';

import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';
import Translator from '../../i18n/Translator';

const ContactsComponent = () => {
  return (
    <StyledGlobalWrapper rowSpacing={6}>
      <Grid item xs={12}>
        <h1 className={classNames('text-4xl')}>
          <Translator path="contacts.title" />
        </h1>
      </Grid>
      <Title item xs={12}>
        <h2 className={classNames('text-xl mb-5')}>
          <Translator path="contacts.subTitle" />
        </h2>
      </Title>
      <ContentWrapper container>
        <SubTitle item xs={12}>
          <span>
            <Translator path="contacts.subTitle2" />
          </span>
        </SubTitle>
        <ContactsContainer item xs={12} sm={4}>
          <span>Instagram</span>
          <div>
            <a href="https://www.instagram.com/thegoats.cc/" target="_blank" rel="noreferrer">
              <InstagramIcon /> @thegoats.cc
            </a>
          </div>
        </ContactsContainer>
        <ContactsContainer item xs={12} sm={4}>
          <span>Telegram</span>
          <div>
            <a href="https://t.me/thegoats_cc" target="_blank" rel="noreferrer">
              <TelegramIcon /> @thegoats_cc
            </a>
          </div>
        </ContactsContainer>
        <ContactsContainer item xs={12} sm={4}>
          <span>
            Email / <Translator path="contacts.mobile" />
          </span>
          <div>
            <a href="mailto:info@thegoats.cc">
              <MailOutlineIcon /> thegoats.cc
            </a>
          </div>
          <div>
            <PhoneIcon /> <span>+351 912 710 678</span>
          </div>
        </ContactsContainer>
      </ContentWrapper>
    </StyledGlobalWrapper>
  );
};

export default ContactsComponent;

const HEADER_AND_FOOTER_HEIGHT = '415';

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    min-height: calc(100vh - ${HEADER_AND_FOOTER_HEIGHT}px);

    margin-top: 90px;
    margin-bottom: 50px;

    h2 {
      font-weight: 200;
      font-style: italic;
    }
    p {
      font-weight: 200;
    }
  }
`;

const Title = styled(Grid)`
  &&&& {
    display: flex;
    justify-content: center;
  }
`;

const ContentWrapper = styled(Grid)`
  &&&& {
    background-color: black;
    color: white;
    padding: 40px;
  }
`;

const SubTitle = styled(Grid)`
  &&&& {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    span {
      font-size: 20px;
    }
  }
`;

const ContactsContainer = styled(Grid)`
  &&&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 20px;
    border-top: 2px solid white;

    > div {
      display: flex;

      span {
        margin-left: 10px;
      }
    }

    a {
      color: white;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`;
