/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import Translator from '../../../../i18n/Translator';

export const soloRegistrationSchema = Yup.object().shape({
  name: Yup.string().required(<Translator path="registrations.form.required" />),
  email: Yup.string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, { message: <Translator path="registrations.form.validEmailRequired" /> })
    .required(<Translator path="registrations.form.validEmailRequired" />),
  phone: Yup.string().required(<Translator path="registrations.form.required" />),
  nationality: Yup.string().required(<Translator path="registrations.form.required" />),
  gender: Yup.string().required(<Translator path="registrations.form.required" />),
  docType: Yup.string().required(<Translator path="registrations.form.required" />),
  docNumber: Yup.string().required(<Translator path="registrations.form.required" />),
  dateOfBirth: Yup.date()
    .max(new Date(Date.now() - 567648000000), <Translator path="registrations.form.minAgeRequired" />)
    .required(<Translator path="registrations.form.required" />),
  address: Yup.string().required(<Translator path="registrations.form.required" />),
  bicycle: Yup.string().required(<Translator path="registrations.form.required" />),
  wayOfTravel: Yup.string().required(<Translator path="registrations.form.required" />),
  bikepackingCompletedRaces: Yup.number().required(<Translator path="registrations.form.required" />),
  favoriteSong: Yup.string().required(<Translator path="registrations.form.required" />),
  user_captcha_input: Yup.string().required(<Translator path="registrations.form.required" />),
  termsAndConditionsAccepted: Yup.bool().oneOf([true], <Translator path="registrations.form.termsRequired" />),
});

export const pairRegistrationSchema = Yup.object().shape({
  name: Yup.string().required(<Translator path="registrations.form.required" />),
  email: Yup.string()
    .required(<Translator path="registrations.form.validEmailRequired" />)
    .email(<Translator path="registrations.form.validEmailRequired" />),
  phone: Yup.string().required(<Translator path="registrations.form.required" />),
  nationality: Yup.string().required(<Translator path="registrations.form.required" />),
  gender: Yup.string().required(<Translator path="registrations.form.required" />),
  docType: Yup.string().required(<Translator path="registrations.form.required" />),
  docNumber: Yup.string().required(<Translator path="registrations.form.required" />),
  dateOfBirth: Yup.date()
    .max(new Date(Date.now() - 567648000000), <Translator path="registrations.form.minAgeRequired" />)
    .required(<Translator path="registrations.form.required" />),
  address: Yup.string().required(<Translator path="registrations.form.required" />),
  bicycle: Yup.string().required(<Translator path="registrations.form.required" />),
  wayOfTravel: Yup.string().required(<Translator path="registrations.form.required" />),
  bikepackingCompletedRaces: Yup.number().required(<Translator path="registrations.form.required" />),
  favoriteSong: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowName: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowEmail: Yup.string()
    .required(<Translator path="registrations.form.validEmailRequired" />)
    .email(<Translator path="registrations.form.validEmailRequired" />),
  fellowPhone: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowNationality: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowGender: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowDocType: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowDocNumber: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowDateOfBirth: Yup.date()
    .max(new Date(Date.now() - 567648000000), <Translator path="registrations.form.minAgeRequired" />)
    .required(<Translator path="registrations.form.required" />),
  fellowAddress: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowBicycle: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowWayOfTravel: Yup.string().required(<Translator path="registrations.form.required" />),
  fellowBikepackingCompletedRaces: Yup.number().required(<Translator path="registrations.form.required" />),
  fellowFavoriteSong: Yup.string().required(<Translator path="registrations.form.required" />),
  user_captcha_input: Yup.string().required(<Translator path="registrations.form.required" />),
  termsAndConditionsAccepted: Yup.bool().oneOf([true], <Translator path="registrations.form.termsRequired" />),
});
