import { createClient } from 'contentful';
import { defaultTo, head, pathOr, pipe, propOr } from 'ramda';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { parseImage } from './utils/parseImage';

// import { parseImage } from './utils/parseImage';

const exist = Boolean;

const getItems = pipe(defaultTo({}), propOr([], 'items'), head);
const getSponsorsItems = pipe(getItems, defaultTo({}), pathOr([], ['fields', 'sponsors']));

/**
 * create Contentful Client
 */
export const client = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
});

/**
 * Retrieve the Sponsors List data from Contentful
 *
 * @returns - list of sponsors
 */
export const getSponsors = async () => {
  const response = await client.getEntries({
    content_type: 'sponsorsList',
  });

  const sponsorsItems = getSponsorsItems(response);

  const sponsorsList = sponsorsItems.map(sponsor => ({
    ...sponsor.fields,
    logoBlack: exist(sponsor?.fields?.logoBlack) ? parseImage(sponsor.fields.logoBlack) : null,
    logoWhite: exist(sponsor?.fields?.logoWhite) ? parseImage(sponsor.fields.logoWhite) : null,
    descriptionPt: exist(sponsor?.fields?.descriptionPt) ? documentToHtmlString(sponsor.fields.descriptionPt) : null,
    descriptionEn: exist(sponsor?.fields?.descriptionEn) ? documentToHtmlString(sponsor.fields.descriptionEn) : null,
  }));

  return sponsorsList;
};
