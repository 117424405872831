import InstaFeedComponent from '../../Components/InstaFeed/InstaFeedComponent';
import FirstSectionComponent from './FirstSection';
import PhotoDumpComponent from './PhotoDump/PhotoDumpComponent';
import SecondSectionComponent from './SecondSection/SecondSectionComponent';

const HomeComponent = () => {
  return (
    <>
      <FirstSectionComponent />
      <SecondSectionComponent />
      <InstaFeedComponent />
      <PhotoDumpComponent />
    </>
  );
};

export default HomeComponent;
