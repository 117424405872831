import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Grid } from '@mui/material';
import GlobalWrapper from '../../../design-system/atoms/GlobalWrapper';
import Translator from '../../../i18n/Translator';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlaceIcon from '@mui/icons-material/Place';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RouteIcon from '@mui/icons-material/Route';
import ForestIcon from '@mui/icons-material/Forest';
import classNames from 'classnames';

const SecondSectionComponent = () => {
  return (
    <StyledGlobalWrapper rowSpacing={5}>
      <Grid item xs={12}>
        <Title className={classNames('font-normal text-4xl')}>
          <Translator path="homePage.secondSection.title" />
        </Title>
      </Grid>
      <Date item xs={5}>
        <StyledSpan className={classNames('font-normal text-base md:text-xl')}>
          <CalendarMonthIcon />
          <Translator path="homePage.secondSection.date" />
        </StyledSpan>
      </Date>
      <Separator item xs={1}>
        |
      </Separator>
      <Place item xs={5}>
        <StyledSpan className={classNames('font-normal text-base md:text-xl')}>
          <PlaceIcon />
          <Translator path="homePage.secondSection.local" />
        </StyledSpan>
      </Place>
      <RouteNumbers item xs={6} sm={4} className={classNames('font-normal text-base md:text-xl')}>
        <RouteIcon /> ~740km
      </RouteNumbers>
      <RouteNumbers item xs={6} sm={4} className={classNames('font-normal text-base md:text-xl')}>
        <TrendingUpIcon /> D+ 17500m
      </RouteNumbers>
      <RouteNumbers item xs={12} sm={4} className={classNames('font-normal text-base md:text-xl')}>
        <ForestIcon /> 70% off-road
      </RouteNumbers>
      <KnowTheRoute item xs={12}>
        <KnowTheRouteButton>
          <Link to="/the-route">
            <Translator path="homePage.secondSection.findOutMore" />
          </Link>
        </KnowTheRouteButton>
      </KnowTheRoute>
      <Registrations item xs={12}>
        <RegistrationsButton>
          <Link to="/registrations">
            <Translator path="homePage.secondSection.registrations" />
          </Link>
        </RegistrationsButton>
      </Registrations>
    </StyledGlobalWrapper>
  );
};

export default SecondSectionComponent;

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    padding-bottom: 50px;
    border-bottom: 1px solid black;
    margin-bottom: 50px;
    margin-top: 10px;
  }
`;

const Title = styled.h1`
  text-align: center;

  margin-top: 50px;
`;

const Date = styled(Grid)`
  &&&& {
    display: flex;
    justify-content: flex-end;
  }
`;

const Place = styled(Date)`
  &&&& {
    justify-content: start;
  }
`;

const Separator = styled(Date)`
  &&&& {
    justify-content: center;
  }
`;

const StyledSpan = styled.span`
  /* font-weight: 400;
  font-size: 18px; */

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const RouteNumbers = styled(Grid)`
  display: flex;
  justify-content: center;
  gap: 10px;

  /* font-size: 20px;
  font-weight: 100; */
`;

const KnowTheRoute = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const Registrations = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const KnowTheRouteButton = styled.div`
  box-sizing: border-box;
  height: 41px;
  width: 187px;
  /* padding: 10px 30px; */
  border: 1px black solid;
  border-radius: 5px;
  background-color: white;
  transition: all 0.3s ease;
  cursor: pointer;

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 41px;
    width: 187px;

    color: black;
    text-decoration: none;

    transition: all 0.3s ease;
  }

  &:hover {
    background-color: black;

    a {
      color: white;
      text-decoration: underline;
    }
  }
`;

const RegistrationsButton = styled.div`
  box-sizing: border-box;
  height: 41px;
  width: 187px;
  /* padding: 10px 30px; */
  border: 1px black solid;
  border-radius: 5px;
  background-color: black;
  transition: all 0.3s ease;
  cursor: pointer;

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 41px;
    width: 187px;

    color: white;
    text-decoration: none;

    transition: all 0.3s ease;
  }

  &:hover {
    a {
      color: white;
      text-decoration: underline;
    }
  }
`;
