import { useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import CookiesPopoverComponent from './Components/CookiesPopover/CookiesPopoverComponent';
import FooterComponent from './Components/Footer/FooterComponent';
import NavbarComponent from './Components/Navbar/NavbarComponent';
import HomeComponent from './Routes/Home';
import CookiesPolicyComponent from './Routes/CookiesPolicy/CookiesPolicyComponent';
import ScrollToTop from './Components/ScrollToTop';
import PrivacyPolicyComponent from './Routes/PrivacyPolicy/PrivacyPolicyComponent';
import TheRouteComponent from './Routes/TheRoute';
import ManifestoComponent from './Routes/Manifesto';
import InfoComponent from './Routes/Info';
import ContactsComponent from './Routes/Contacts';
import PartnersComponent from './Routes/Partners';
import RefundPolicyComponent from './Routes/RefundPolicy/RefundPolicyComponent';
import FollowMeComponent from './Routes/FollowMe';
// import RegistrationsClosedComponent from './Routes/RegistrationsClosed/RegistrationsClosedComponent';
import RegistrationsWithStripeComponent from './Routes/RegistrationsWithStripe/RegistrationsWithStripeComponent';
import SuccessComponent from './Routes/RegistrationsWithStripe/Success/SuccessComponent';
import CancelComponent from './Routes/RegistrationsWithStripe/Cancel/CancelComponent';

function App() {
  const [cookies, setCookie] = useCookies(['cookieAccepted']);

  const showCookiesPopover = useMemo(() => !cookies.cookieAccepted || cookies.cookieAccepted === 'false', [cookies]);

  const handleCookieAccepted = () => {
    setCookie('cookieAccepted', true);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>The GOATS</title>
        <meta
          name="description"
          content="The GOATS - Ultra distance & unsupported cycling event. 740km of gravel (75% offroad) with 16.500 meters of elevation gain."
        />
      </Helmet>

      <BrowserRouter>
        <Main>
          <ScrollToTop />
          <NavbarComponent />
          <Routes>
            <Route path="/" element={<HomeComponent />} key={Date.now()} />
            <Route path="/the-route" element={<TheRouteComponent />} />
            <Route path="/manifesto" element={<ManifestoComponent />} />
            <Route path="/info" element={<InfoComponent />} />
            <Route path="/contacts" element={<ContactsComponent />} />
            <Route path="/partners" element={<PartnersComponent />} />
            <Route path="/cookies-policy" element={<CookiesPolicyComponent />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyComponent />} />
            <Route path="/refund-policy" element={<RefundPolicyComponent />} />

            {/* <Route path="/registrations" element={<RegistrationsClosedComponent />} /> */}
            <Route path="/registrations" element={<RegistrationsWithStripeComponent />} />
            <Route path="/registration-success" element={<SuccessComponent />} />
            <Route path="/registration-cancel" element={<CancelComponent />} />
            <Route path="/editions" element={<FollowMeComponent />} />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <FooterComponent />
          {showCookiesPopover && <CookiesPopoverComponent handleCookieAccepted={handleCookieAccepted} />}
        </Main>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;

const Main = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
`;
