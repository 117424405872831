export const form = {
  required: 'Campo obrigatório',
  validEmailRequired: 'É obrigatório um email válido',
  name: 'Nome',
  email: 'Email',
  phone: 'Telemóvel',
  nationality: 'Nacionalidade',
  gender: 'Sexo',
  male: 'Masculino',
  female: 'Feminino',
  docType: 'Documento de Identificação',
  docTypeNational: 'Documento Nacional',
  docTypePassport: 'Passaporte',
  docNumber: 'Número do Documento',
  dateOfBirth: 'Data de Nascimento',
  minAgeRequired:
    'Para te inscreveres deves ser maior de idade. Se esse não for o caso contacta-nos através do email info@thegoats.cc.',
  address: 'Onde moras? (País, cidade)',
  bicycle: 'Que tipo de bicicleta vai utilizar? (BTT ou Gravel)',
  wayOfTravel: 'Como pensas viajar até ao local de partida?',
  bikepackingCompletedRaces: 'Quantas corridas de Bikepacking já fizeste?',
  favoriteSong: `Canção preferida (ou que ouves no momento)?`,
  category: 'Categoria',
  solo: 'Solo - 210€',
  pair: 'Duplas - 400€',
  userAlreadyExistTitle: 'Utilizador já inscrito',
  userAlreadyExistMessage:
    'O e-mail {{email}} já está registado. Por favor, verifique se o e-mail está correto. Se o problema persistir, envie-nos um e-mail para info@thegoats.cc!',
  emailInvalidTitle: 'O e-mail não é válido',
  emailInvalidMessage:
    'O e-mail que tentou registar não é válido. Por favor, verifique se o e-mail está correto. Se o problema persistir, envie-nos um e-mail para info@thegoats.cc!',
  terms: 'Aceito os termos e condições, bem como confirmo que li e aceito o Manifesto disponível neste site.',
  termsRequired: 'Para te inscreveres é obrigatório aceitares os termos e condições.',
  goToPayment: 'Avançar para o pagamento!',
  registrationConfirmed:
    'Bem vindo ao the GOATS!<br/>Agora é oficial: estás na corrida. A tua inscrição está confirmada. Vemos-te em breve!',
  registrationError:
    'Alguma coisa correu mal durante o processo de inscrição.<br/>Em princípio não foi cobrado qualquer valor. De qualquer forma, pedimos que confirmes e que te tentes inscrever novamente. Se o problema persistir, por favor entra em contacto connosco através do info@thegoats.cc.',
  fellow: 'Dupla - ',
};
