import styled from 'styled-components';
import classNames from 'classnames';

import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';

import Translator from '../../i18n/Translator';

const isEven = number => number % 2 === 0;

const PARAGRAPH_STYLE = 'font-extralight mb-6';

const CHECK_POINTS_LIST = [
  { title: 'theRoute.cp1.title', description: 'theRoute.cp1.description', image: '/images/cp-gardunha.jpg' },
  { title: 'theRoute.cp2.title', description: 'theRoute.cp2.description', image: '/images/cp-lousa.jpg' },
  { title: 'theRoute.cp3.title', description: 'theRoute.cp3.description', image: '/images/cp-caramulo.jpg' },
  { title: 'theRoute.cp4.title', description: 'theRoute.cp4.description', image: '/images/cp-serra-da-estrela.jpg' },
];

const CheckPoint = ({ checkPoint, fotoSide = 'left' }) => {
  const { title, description, image } = checkPoint;

  return (
    <div className={classNames('md:grid md:grid-cols-[1fr_1fr]', 'gap-5 my-12')}>
      <img className={classNames('md:hidden mb-4')} src={image} alt="the goats checkpoint" />

      {fotoSide === 'left' && <img className={classNames('hidden md:block')} src={image} alt="the goats checkpoint" />}

      <div>
        <h1 className={classNames('text-xl mb-4')}>
          <Translator path={title} />
        </h1>
        <p className={classNames('font-extralight')}>
          <Translator path={description} />
        </p>
      </div>

      {fotoSide === 'right' && <img className={classNames('hidden md:block')} src={image} alt="the goats checkpoint" />}
    </div>
  );
};

function TheRouteComponent() {
  const titleRenderer = () => (
    <h1 className={classNames('font-normal text-4xl pb-5')}>
      <Translator path="theRoute.pageTitle" />
    </h1>
  );

  const introRenderer = () => (
    <p className={classNames(PARAGRAPH_STYLE)}>
      <Translator path="theRoute.intro" />
    </p>
  );

  return (
    <StyledGlobalWrapper rowSpacing={6}>
      <div className={classNames('flex flex-col w-full')}>
        {titleRenderer()}
        {introRenderer()}
        {CHECK_POINTS_LIST.map((cp, idx) => (
          <CheckPoint key={cp.title} checkPoint={cp} fotoSide={isEven(idx) ? 'left' : 'right'} />
        ))}
      </div>
    </StyledGlobalWrapper>
  );
}

export default TheRouteComponent;

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    margin-top: 130px;
    margin-bottom: 50px;
  }
`;
