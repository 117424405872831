import classNames from 'classnames';

import { Tab } from '@headlessui/react';

import Translator from '../../../../i18n/Translator';

const TabsComponent = ({ tabs = {}, tabChanged }) => {
  return (
    <div className="w-full px-1 py-16 sm:px-0">
      <Tab.Group
        onChange={index => {
          tabChanged(index);
        }}>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          {Object.keys(tabs).map(tab => (
            <Tab
              key={tab}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-md leading-5 text-black font-bold',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                )
              }>
              <Translator path={`registrations.form.${tab}`} />
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(tabs).map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
              )}>
              {tab}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default TabsComponent;
