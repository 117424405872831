const theme = {
  margins: {
    sideOuterMargin: 32,
  },
  fontSize: {
    navBarLogo: 18,
  },
  fontWeight: {
    navBarLogo: 800,
  },
};

export default theme;
