export const info = {
  info0: {
    title: 'Datas',
    p1: '5 de Outubro - Acreditação',
    p2: '6 de Outubro, 09h00 - Início da prova',
    p3: '11 de Outubro, 11h59 - Tempo limite',
    p4: "1 mês antes do evento irão receber o 'Riders Book'",
    p5: "3 a 5 dias antes do evento irão receber um 'Video Briefing' com todas as informações sobre o evento",
  },
  info1: {
    title: 'Quanto custa a inscrição?',
    p1: 'Solo - 210€',
    p2: 'Pares - 400€',
  },
  info2: {
    title: 'O que inclui o kit de participante?',
    p1: 'Ridersbook (PDF)',
    p2: 'Lembrança de Finisher',
    p3: 'Boné de ciclismo com número de participante',
    p4: 'Saco musette',
    p5: 'Conteúdo media (fotografias e vídeo após evento)',
  },
  info3: {
    title: 'Qual o material obrigatório?',
    p1: 'Capacete, Luzes frontais e dianteiras, colete reflector e manta de sobrevivência.',
  },
  info4: {
    title: 'Onde posso encontrar comida e água',
    p1: 'Ao longo do percurso estão disponíveis muitos locais com água e comida. Uma das melhores coisas que Portugal tem é a sua comida.',
  },
  info5: {
    title: 'Como é o clima?',
    p1: 'Na semana em que decorre o desafio, o histórico meteorológico, dá-nos a informação de que as temperaturas máximas variam entre os 24~28ºC e as mínimas entre os 12~16ºC, contudo considerar sempre temperaturas mais baixas em locais de altitude acima dos 1000m. É pouco usual chover nesta altura do ano.',
  },
  info6: {
    title: 'Qual o tipo de bicicleta e pneus recomendados?',
    p1: 'É um evento off-road e ambas as bikes (gravel vs btt) são adequadas. No caso de bicicletas de gravel aconselhamos um pneu de pelo menos 700x43cc e uma relação de transmissão para subida, de pelo menos 38x46.',
    p2: 'Temos por certo que poderá ser dos melhores desafios de ultra distância que poderão experienciar numa bicicleta.',
  },
  info7: {
    title: 'Como chegar?',
    p1: 'Portugal em território é um País pequeno com uma boa rede de infraestruturas que facilmente permitem chegar à Serra da Estrela, local de partida e chegada da aventura.',
    p2: 'Dois aeroportos principais a cerca de 270km das Penhas da Saúde- Covilhã, o Aeroporto Humberto Delgado em Lisboa e o Aeroporto Sá Carneiro na cidade do Porto.',
    p3: 'Cerca de 3 horas, separam qualquer um dos aeroportos do local da acreditação e partida do the GOATS. Seja de carro ou comboio (www.cp.pt).',
  },
  info8: {
    title: 'Onde ficar?',
    p1: 'Aconselhamos vivamente a deslocarem-se uns dias antes e até ficarem uns dias depois da vossa aventura.',
    p2: 'A Serra da Estrela, bem como todos os locais que percorrerão na vossa aventura, dispõem de uma alargada infraestrutura hoteleira que domina bem a arte de receber. Uma região rica em história e paisagens. Com uma gastronomia sem igual que certamente vos ficará na memória do paladar.',
    p3: 'Links úteis para vos auxiliarem na vossa estadia:',
    link1: 'http://www.centerofportugal.com',
    link2: 'http://www.aldeiashistoricasdeportugal.com',
    link3: 'http://www.turismodocentro.pt',
    link4: 'http://www.visiteserradaestrela.pt',
    link5: 'http://www.geoparkestrela.pt',
    link6: 'http://www.visitportugal.com',
    link7: 'http://www.aldeiasdoxisto.pt',
  },
};
