import { ErrorMessage, Field } from 'formik';
import classNames from 'classnames';

import Translator from '../../../../i18n/Translator';

const lowerFirstLetter = string => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

const RadioField = ({ name, options, preLabel = '' }) => {
  const labelName = lowerFirstLetter(name.replace('fellow', ''));

  return (
    <div className={classNames('flex flex-col gap-2', 'my-4')}>
      <div id={name}>
        {preLabel}
        <Translator path={`registrations.form.${labelName}`} />
      </div>
      <div role="group" aria-labelledby={name}>
        {options.map(option => (
          <label key={option} className={classNames('mr-4')}>
            <Field className={classNames('mr-2')} type="radio" name={name} value={option} />
            <Translator path={`registrations.form.${option}`} />
          </label>
        ))}
      </div>
      <ErrorMessage name={name} component="span" className="text-red-600" />
    </div>
  );
};

export default RadioField;
