import classNames from 'classnames';
import Translator from '../../../i18n/Translator';

const CancelComponent = () => {
  return (
    <>
      <img
        className={classNames('w-full mt-32 md:mt-0')}
        src="/images/registration-error-banner.jpg"
        alt="the goats registration error banner"
      />
      <div className={classNames('max-w-[1200px] w-full mx-auto mb-32')}>
        <h1 className={classNames('text-4xl my-4')}>Ups...!</h1>
        <p>
          <Translator path="registrations.form.registrationError" />
        </p>
      </div>
    </>
  );
};

export default CancelComponent;
