import RadioField from '../Fields/RadioField';
import TextField from '../Fields/TextField';

const IndividualFields = props => {
  const { isFellow } = props;

  return (
    <>
      <TextField name={isFellow ? 'fellowName' : 'name'} {...props} />
      <TextField name={isFellow ? 'fellowEmail' : 'email'} type="email" {...props} />
      <TextField name={isFellow ? 'fellowPhone' : 'phone'} {...props} />
      <TextField name={isFellow ? 'fellowNationality' : 'nationality'} {...props} />
      <TextField name={isFellow ? 'fellowAddress' : 'address'} {...props} />
      <RadioField name={isFellow ? 'fellowGender' : 'gender'} options={['male', 'female']} {...props} />
      <TextField type="date" name={isFellow ? 'fellowDateOfBirth' : 'dateOfBirth'} {...props} />
      <RadioField name={isFellow ? 'fellowDocType' : 'docType'} options={['docTypeNational', 'docTypePassport']} {...props} />
      <TextField name={isFellow ? 'fellowDocNumber' : 'docNumber'} {...props} />
      <TextField name={isFellow ? 'fellowBicycle' : 'bicycle'} {...props} />
      <TextField name={isFellow ? 'fellowWayOfTravel' : 'wayOfTravel'} {...props} />
      <TextField
        type="number"
        min={0}
        name={isFellow ? 'fellowBikepackingCompletedRaces' : 'bikepackingCompletedRaces'}
        {...props}
      />
      <TextField name={isFellow ? 'fellowFavoriteSong' : 'favoriteSong'} {...props} />
    </>
  );
};

export default IndividualFields;
