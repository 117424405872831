import { form } from './En/form';
import { info } from './En/info';
import { theRoute } from './En/theRoute';
import { partners } from './En/partners';

const translationData = {
  translations: {
    modal: {
      ok: 'I understand!',
    },
    menu: {
      item1: { label: 'the Goats' },
      item2: {
        label: 'The Route',
      },
      item3: {
        label: 'Manifesto',
      },
      item4: {
        label: 'Info',
      },
      item5: {
        label: 'Contacts',
      },
      item6: {
        label: 'Partners',
      },
      item7: {
        label: 'Editions',
      },
    },
    homePage: {
      firstSection: {
        subTitle: 'Ultra distance & unsupported cycling',
      },
      secondSection: {
        title: 'The challenge',
        date: 'October 5th - October 11th',
        local: 'Serra da Estrela - Portugal',
        findOutMore: 'Find out more',
        registrations: 'Sign up now!',
      },
      highlightsSection: {
        photo1: {
          title: 'Malcata',
          description:
            'Serra da Malcata, a nature reserve, the land of the Iberian Lynx. A rugged mountain range with a rounded ridge that joins the Sierra de Gata in Spain. At its foot are the floodplain forests of the River Côa and the banks of the Meimoa, which offer a breathtaking journey and make us forget any tiredness. In this mountain range, you can enjoy a fantastic sunrise or a magnificent sunset. And with a bit of luck, you will see a magnificent lynx.',
        },
        photo2: {
          title: 'Monsanto',
          description:
            'A place so iconic, rising on a particular rocky elevation, and its village so symbiotically interwoven with its surroundings. The oldest part is on the highest point, where the Templars built a fence with a keep. A village with such a rich history, dating back to the Visigothic, Arab and Roman occupations, and even traces of a Lusitanian castro. For more information about this historic village, visit: https://aldeiashistoricasdeportugal.com',
        },
        photo3: {
          title: 'Gardunha',
          description:
            'After the village of Castelo Novo (Historic Village), a long climb begins on perfect gravel that takes you to the summit, coinciding with the highest point at 1227 metres in Penha, from where, according to the poet José Régio, you can see “the lands of Spain and the sands of Portugal”. In this Serra, at its summit, you can contemplate the Serra da Estrela to the north, where you started and where you will GOAT. It is located in a region that produces a delicious fruit, the cherry, and there are even wild cherry trees along the way. Here we cross the river Zêzere for the second time, which rises in the Serra da Estrela at 1900 m above sea level, and descend through the glacial valley, just as you did at the beginning of this adventure.',
        },
        photo4: {
          title: 'Serra da Lousã',
          description:
            'Of an incredible green, this mountain full of mysticism offers us a lot.  known for its Schist Villages such as Candal, Gondramaz, Talasnal, Cerdeira and others. It belongs to the northern branch of the Central Cordillera, composed of the Estrela, Açor and Lousã mountains, and is also part of the Montejunto-Estrela system. And from here, with many but good kilometres in the legs, we head north towards Buçaco.',
        },
        photo5: {
          title: 'Luso',
          description:
            'One of the largest biodiversity reserves in Portugal, with around 250 species of shrubs and trees, many of which are centuries old. And with one of the most beautiful palaces (hotel) in Portugal, we are in the Buçaco National Forest. It is impossible to put into words the beauty of the gardens of the palace that you will enter through the gates of Coimbra, which are always open to welcome you. And from now on, you will develop an enormous affinity with the Mondego River, the largest exclusively Portuguese river, which rises in the very Serra da Estrela where you started and where you will arrive, just like the Zêzere River.',
        },
        photo6: {
          title: 'Torre, Serra da Estrela',
          description:
            'And behold, you have arrived in the majestic Serra da Estrela. The last great difficulty still lies ahead, but you are about to become GOAT. Long climbs, but with breathtaking views, colour contrasts, vegetation and air so clean that you will feel strong for this last difficulty. The last kilometres are on easier terrain (asphalt) so you can enjoy the last views, or perhaps a sky so starry and dazzling that you will never forget it, and take in everything you have just conquered in retrospect, or arrive at the top of the Serra da Estrela in perfect “mindfulness” and we can embrace you and present you with the symbol of your CONQUER.',
        },
      },
    },
    footer: {
      contacts: 'Contacts',
      legalPages: 'Legal Pages',
      legalNotice: 'Legal notice',
      privacyPolicy: 'Privacy Policy',
      cookiesPolicy: 'Cookies Policy',
    },
    cookies: {
      bar: {
        title: 'Cookies',
        details:
          'This website uses cookies and other tracking technologies to aid your browsing and our ability to provide feedback, analyze the use of our website and help provide promotional information about our services and products.',
        acceptButton: 'Accept',
      },
      page: {
        pageTitle: 'Cookies Policy',
        section1: {
          title: 'What are cookies?',
          p1: "Cookies are small text files that a website places on the user's computer or mobile device when visited via the Internet browser (browser). The placement of cookies helps the website recognize the device on a next visit and is sometimes necessary for proper functioning. We use the term cookies in this policy for all files that collect information in this way.",
        },
        section2: {
          title: 'How we use cookies',
          p1: 'The cookies used on this website by The Goats are as follows:',
          p2: '1. Strictly necessary cookies and functionality cookies:',
          p3: "- To enable or optimize the functionalities offered by the website and improve the user's browsing experience.",
          p4: '2. Analytical cookies:',
          p5: '- Are installed by tools managed by third parties (Google Analytics, etc.) to monitor the quality of the user experience when browsing this website.',
          p6: '3. Third party cookies:',
          p7: '- Facebook, YouTube, Vimeo, OpenStreetMap and other tools managed by third parties may install cookies to share content on social networks or to generate query statistics. The presence, number and status of these cookies may depend on how you use the relevant platforms before or during a visit to this website. It is useful to check the cookie management policies of these social networks on their websites. We sometimes use third-party web services to display content on our website, such as maps or videos. As with social network sharing buttons, we cannot prevent these websites or external domains from collecting information about the use of this embedded content.',
        },
        section3: {
          title: 'What are cookies used for?',
          p1: 'Cookies are used to determine the usefulness, interest and number of uses of the websites, to allow faster and more efficient navigation and to avoid the repeated entry of the same information.',
        },
        section4: {
          title: 'Do you want to refuse a cookie through your browser?',
          p1: 'You can decide at any time whether you want to disable all or part of the cookies. The browser can also be configured to inform you of the cookies present on the screen and ask you to accept them or not (on a case-by-case basis or completely). However, we inform you that disabling all cookies will prevent you from using the website under normal conditions, except for the basic functions.',
        },
      },
    },
    theRoute: theRoute,
    info: info,
    refundPolicy: {
      title: 'Refund Policy',
      p1: 'Our Refunds Policy as the organization of these kind of events allows due to the commitments with suppliers, media team, logistics and other main issues needed:',
      b1: 'Refund until 15/AGO/2024: 50% of entry fee.',
      b2: 'No refunds after 15/AGO/2024.',
      p2: 'If the event is cancelled as a result natural disaster, war, strike, legal interventions, major accidents during the event, pandemics, lockdowns or other such causes, the GOATS will reschedule the event. In this case the riders would have two options: first to keep the place for the new date, second -during a limited period of time- a refund of 80% of entry fee, if this happens after 15/AGO/2024.',
      p3: 'In case of adverse weather or risk conditions the track will be partially rerouted. Once the event has begun, no refund will be offered if it is cancelled, postponed or rerouted.',
      p4: 'Any change of category proposed by the riders will have a charge of 20€/rider. No changes allowed after 15/AGO/2024.',
      p5: 'If you have any questions please contact us at',
    },
    partners: partners,
    manifesto: {
      rule1: {
        title: 'LEAVE NO TRACE',
        description1:
          'The only marks you should leave on your journey are those of your tyres. Respect nature. You will be crossing some fragile ecosystems and protected areas, so leave nature unchanged.',
      },
      rule2: {
        title: 'RIDE SAFELY, RIDE RESPONSIBLY',
        description1:
          'The GOATS uses roads that are open to traffic, and you must always observe and comply with local and municipal laws. Safety always comes first and the route set by the organisation is suitable for cyclists and complies with local regulations.',
        description2:
          'Helmet, front and rear lights, and high-visibility vest are mandatory at night. Be visible to other users of the common roads.',
      },
      rule3: {
        title: 'ONLY YOU (HONESTY AND HONOR)',
        description1:
          'Only you, your strength and your bike (e-bikes are not allowed), no outside help. Drafting is not allowed, unless you participate in pairs or in neutralized phases, which will be indicated by the organization in time. Side-by-side is allowed.',
        description2:
          'In case of emergency, you can leave the course, but you must get back on at the exact exit point and continue the route.',
      },
      rule4: {
        title: 'BE SELF-SUFFICIENT',
        description1:
          'Food, drink and belongings must be carried by you or acquired unplanned en-route. You are responsible for you own safety and logistics.',
        description2:
          'Booking accommodation or any logistic support before the ride is not allowed. Support crew or virtual base-camps are prohibited, but unexpected and unrequested help en-route is allowed.',
        description3:
          'The pair is considered as a rider itself, they can share all the resources between themselves but external assistance is not allowed.',
      },
      rule5: {
        title: 'STICK TO THE TRACK',
        description1:
          'You must follow the track we have given you. If the route is blocked by something unexpected (e.g., construction sites, landfalls, trees on the path, etc.), you may leave the track but you must return to it as soon as possible.',
        description2: 'Intentional shortcuts will cost a time penalty.',
        description3:
          'You may also leave the route temporarily (to eat, rest, etc.), but you must return to the same point where you left off to continue the ride. In the event of a medical or mechanical emergency, use whatever transportation you need and return to the same point to continue the ride.',
      },
      rule6: {
        title: 'BE ON TIME',
        description1: 'Be on time for accreditation, briefing and start.',
        description2:
          'If you do not finish the course before the deadline, you will be scored as DNF. You cannot expect official service or support after this deadline, but your ride can continue: Just return the tracker, relax, and enjoy your route.',
        description3:
          'If one of the paired riders drops out, the pair will be scored as DNF, but the other rider can continue and will be scored as finisher (out of category).',
      },
      rule7: {
        title: 'CONTROL YOUR TRACKER',
        description1:
          'Satellite tracking is only a service to follow the riders online: You are responsible for the proper use of the tracker. Turning off the tracker or extended periods of inactivity without reporting to the organisation will result in penalties.',
        description2: 'If the pair rides separately for any reason, the tracker must be worn by the last rider.',
        description3: 'A refundable deposit against loss or damage will be required for each tracker.',
      },
      rule8: {
        title: 'RESPECT ALL',
        description1:
          'Please be kind with the staff to them, they have worked hard and invested their time and effort to support this adventure, and whit all other participants and locals.',
        description2: 'Follow the advice of the crew, they will only do it for your safety and the good progress of the trip.',
      },
      rule9: {
        title: 'INSURANCE',
        description1:
          'Valid liability, death, accident and medical insurance is mandatory for the duration of the event and also covers the cost of retrieval and return transportation.',
        description2: 'Please be aware that all responsibility lies with you.',
        description3:
          'However, the organization will provide personal accident insurance to reinforce the insurance that everyone should have.',
      },
      rule10: {
        title: 'BE RESPONSIBLE AND REASONABLE',
        description1:
          'Adhere to all the rules described here, pay attention to your body and make your decisions sensibly and rationally, without losing sight of the dimension of the challenge and the control of your efforts, and do not overdo it with sleep deprivation, because in this way you will be more exposed to accidents due to lack of concentration and reflexes.',
        description2:
          'Study the route and plan your adventure carefully, thinking of all the elements that can contribute to your success and an unforgettable experience.When people ask us how can we ensure riders comply with the rules, we always answer the same: we can’t. The honorable agreement is inherent to bikepacking adventures. This can be a fair ride, it just depends on you.',
      },
    },
    contacts: {
      title: 'Contacts',
      subTitle: 'We are always available to answer your questions and hear your thoughts',
      subTitle2: 'You can reach us by',
      mobile: 'Mobile',
    },
    privacyPolicy: {
      pageTitle: 'Privacy Policy',
      p1: 'At UBE - Unsupported bike events, association we understand how important it is to you how we use and manage your personal or professional contact information. This Privacy Policy is designed to help you understand what information we collect and for what purpose we treat it, as well as our efforts to protect it with the necessary guarantees and in accordance with applicable regulations.',
      p2: "The personal data that you provide to us through this website at the URL https://thegoats.cc/ (hereinafter the 'Website') will be treated as described below:",
      question1: 'Who is responsible for processing your data?',
      p3: 'In accordance with Law 34/2002 on Information Society Services and Electronic Commerce (hereinafter LSSI), General Regulation (EU) 2016/679 on Data Protection (hereinafter RGPD) and Organic Law 3/2018 on the Protection of Personal Data and the Guarantee of Digital Rights (hereinafter LOPDgdd), we inform you, that the personal data you provide when filling out and sending the forms published on the website will be included in the files that constitute the activity record of UBE-Unsupported Bike Events, Association, with NIPC 517392607 and registered office in R. Condes de Atouguia, 2488 A-B, Sesimbra - Portugal',
      question2: 'What user data do we process?',
      p4: 'UBE-Unsupported Bike Events processes the following categories of user data:',
      p5: 'Identification data: First and last name.',
      p6: 'Personal or professional contact information: Postal address, email address, telephone.',
      p7: 'Other data: Data provided by those interested in promoting business relations and mutual interest.',
      question3: 'For what purpose and on what legitimate basis do we process your data?',
      p8: 'Management of contact requests and information received by email and/or web form. Legal basis of legitimacy: consent or legitimate interest of the data subject (Art. 6.1 a) and f) RGPD).',
      p9: 'Management of the contractual or commercial relationship between the user of UBE-Unsupported Bike Events and execution of the contract binding the parties. Legal basis for legitimation: conclusion of a contract or commercial and business relationship (Art. 6.1 b) RGPD).',
      p10: 'Compliance with the binding obligations provided for by the law. Legal basis for legitimation: compliance with the legal obligation (Art. 6.1 c) GDPR).',
      p11: 'Management of the relationship between the user as professional contact and/or representative of a legal entity and UBE-Unsupported Bike Events, including sending commercial communications, news of interest, events, conferences, races and other information about services similar to those originally contracted. Legal basis of legitimacy: legitimate and mutual interest of the parties (Art. 6.1 f) GDPR).',
      p12: 'Processing of sending the newsletter, publications and other newsletters to its subscribers. Legal basis for legitimacy: explicit consent (Art. 6.1 a) GDPR).',
      question4: 'To whom will we share your personal data?',
      p13: 'UBE-Unsupported Bike Events transfers personal data only to:',
      p14: 'Third parties, public bodies and institutions of the general state administration, regional and local governments, including judicial bodies, to which it is legally obliged to disclose it.',
      p15: 'To cooperating companies and/or partners so that they can manage the contractual relationship with you.',
      p16: 'To service providers contractually associated with UBE-Unsupported Bike Events, as data controllers, who will handle your data in strict compliance with data protection regulations and with the necessary guarantees for the purposes of the processing.',
      question5: 'How long will we keep your data?',
      p17: 'The personal data provided to fulfill the obligations arising from the contractual relationship between the user and UBE-Unsupported Bike Events will be kept for the entire duration of the business relationship; after the termination of the business relationship, the data will be kept during the limitation period for lawsuits that may arise from the relationship maintained with the user.',
      p18: 'However, if you have provided your data on the basis of a legitimate mutual interest with a view to promoting future business relations, your data will be kept until you express your wish to withdraw this consent.',
      question6: 'What security measures are taken for your personal data?',
      p19: 'The personal data provided will be processed using the necessary technical and organizational measures to prevent the loss, misuse, alteration and unauthorized access to the data, taking into account the state of the art, the nature of the data and the risk analysis carried out.',
      question7: 'How can you exercise your rights in relation to your personal data?',
      p20: 'For the revocation of consent given and for the exercise of the rights of access, rectification, erasure, objection, restriction and portability, as well as for the refusal of an automated individual decision, you can contact us - info@thegoats.cc',
    },
    registrations: {
      pageTitle: 'Registrations',
      announcement: 'Registrations are closed.',
      form: form,
    },
  },
};

export default translationData;
