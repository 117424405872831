import { ErrorMessage, Field } from 'formik';
import classNames from 'classnames';

import Translator from '../../../../i18n/Translator';

const lowerFirstLetter = string => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

const InputField = ({ type, min, name, formikProps, preLabel = '' }) => {
  const { errors, touched } = formikProps;

  const labelName = lowerFirstLetter(name.replace('fellow', ''));

  return (
    <div className={classNames('flex flex-col gap-2', 'my-4')}>
      <label className={classNames('mr-4')} htmlFor={name}>
        {preLabel}
        <Translator path={`registrations.form.${labelName}`} />
      </label>
      <Field
        type={type}
        name={name}
        id={name}
        min={min}
        onWheel={event => event.target.blur()}
        className={classNames(
          'hover:border-2 border-gray-400 hover:border-dashed rounded-md',
          'bg-gray-200',
          'h-10 px-2',
          errors[name] && touched[name] ? 'border-2 border-red-600' : null,
        )}
      />
      <ErrorMessage name={name} component="span" className="text-red-600" />
    </div>
  );
};

export default InputField;
