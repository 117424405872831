import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Menu, Transition } from '@headlessui/react';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu as MUIMenu } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import theme from '../../theme/theme';
import Translator from '../../i18n/Translator';
import useNavbar from './hooks/useNavbar';

const LAST_BUTTON_PADDING = 8;
const LAST_BUTTON_MARGIN = 12;

const NavbarComponent = () => {
  const [scroll, setScroll] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const { isHomePage } = useNavbar();

  const { i18n } = useTranslation();

  const selectedLanguage = i18n.language;

  const portugueseSelected = selectedLanguage === 'pt-PT';

  const handleChangeLanguage = language => {
    i18n.changeLanguage(language);
  };

  const mobileMenuOpen = Boolean(anchorEl);
  const handleOpenMobileMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMobileMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY);
    });
  }, []);

  const menu = [
    { title: <Translator path="menu.item1.label" />, link: '/' },
    {
      title: <Translator path="registrations.pageTitle" />,
      link: '/registrations',
    },
    {
      title: <Translator path="menu.item2.label" />,
      link: '/the-route',
    },
    {
      title: <Translator path="menu.item3.label" />,
      link: '/manifesto',
    },
    { title: <Translator path="menu.item4.label" />, link: '/info' },
    {
      title: <Translator path="menu.item5.label" />,
      link: '/contacts',
    },
    {
      title: <Translator path="menu.item6.label" />,
      link: '/partners',
    },
    {
      title: <Translator path="menu.item7.label" />,
      link: '/editions',
    },
  ];

  const selectLanguageButton = () => (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center text-white font-light px-2 hover:underline">
            {portugueseSelected ? 'Pt' : 'En'}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-black/60 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2`}
                    onClick={() => {
                      if (portugueseSelected) handleChangeLanguage('en-US');
                      else handleChangeLanguage('pt-PT');
                    }}>
                    {portugueseSelected ? 'En' : 'Pt'}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );

  return (
    <Wrapper>
      <BackgroundShadow isHomePage={isHomePage} scroll={scroll} />
      <LogoContainer>
        <Link reloadDocument to="/">
          <img src="/images/the-goats-logo-v2.png" alt="ultracycling cycling ultradistance gravel" />
        </Link>
      </LogoContainer>
      <DesktopMenuContainer>
        {menu &&
          menu.map((item, idx) => (
            <div key={`desktop-${item.link}`}>
              <Link to={item.link} reloadDocument={idx === 0} onClick={() => setAnchorEl(null)}>
                {item.title}
              </Link>
            </div>
          ))}
        {selectLanguageButton()}
      </DesktopMenuContainer>
      <MobileMenuTrigger>
        <IconButton
          id="mobile-menu-trigger"
          onClick={handleOpenMobileMenu}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={mobileMenuOpen ? 'mobile-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={mobileMenuOpen ? 'true' : undefined}>
          <StyledMenuIcon />
        </IconButton>
        <MobileMenu
          id="mobile-menu"
          MenuListProps={{
            'aria-labelledby': 'mobile-menu-trigger',
          }}
          anchorEl={anchorEl}
          open={mobileMenuOpen}
          onClose={handleCloseMobileMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <MobileMenuWrapper>
            <div>
              <StyledCloseIcon onClick={handleCloseMobileMenu} />
            </div>
            {menu &&
              menu.map((item, idx) => (
                <div key={`mobile-${item.link}`}>
                  <Link to={item.link} reloadDocument={idx === 0} onClick={() => setAnchorEl(null)}>
                    {item.title}
                  </Link>
                </div>
              ))}
            {selectLanguageButton()}
          </MobileMenuWrapper>
        </MobileMenu>
      </MobileMenuTrigger>
    </Wrapper>
  );
};

export default NavbarComponent;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 1000;

  height: 100px;
  width: 100%;
  padding: 18px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackgroundShadow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background-color: black;
  opacity: ${({ scroll, isHomePage }) => ((scroll && scroll > 5) || !isHomePage ? '0.6' : '0')};
  transition: all 0.6s;
`;

const LogoContainer = styled.div`
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: ${theme.fontSize.navBarLogo}px;
    font-weight: ${theme.fontWeight.navBarLogo};
    text-decoration: none;

    position: relative;
    margin-left: ${theme.margins.sideOuterMargin}px;
  }
  img {
    height: 65px;
  }
`;

const DesktopMenuContainer = styled.div`
  &&&& {
    @media (max-width: 920px) {
      display: none;
    }

    display: flex;
    z-index: 1000;

    margin-right: ${theme.margins.sideOuterMargin - LAST_BUTTON_PADDING - LAST_BUTTON_MARGIN}px;

    a {
      margin: 0 12px;
      display: flex;
      align-items: center;
      font-weight: 300;
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const MobileMenuTrigger = styled.div`
  &&&& {
    @media (min-width: 921px) {
      display: none;
    }

    margin-right: ${theme.margins.sideOuterMargin - LAST_BUTTON_PADDING - LAST_BUTTON_MARGIN}px;
  }
`;

const MobileMenu = styled(MUIMenu)`
  &&&& .MuiPaper-root {
    @media (min-width: 921px) {
      display: none;
    }

    background-color: black;

    width: 100vw;
    max-width: 100vw;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
`;

const MobileMenuWrapper = styled.div`
  padding: 20px;

  > div:first-child {
    text-align: end;

    svg {
      cursor: pointer;
    }
  }

  > div {
    padding: 10px;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  &&&& {
    color: white;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  &&&& {
    color: white;
  }
`;
