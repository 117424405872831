import { Box, Grid } from '@mui/material';
import styled from 'styled-components';

const GlobalWrapper = ({
  spacing = 0,
  fullWidth = false,
  justifyContent = 'center',
  alignItems = 'center',
  children,
  ...rest
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Wrapper
        container
        spacing={spacing}
        fullWidth={fullWidth}
        justifyContent={justifyContent}
        alignItems={alignItems}
        {...rest}>
        {children}
      </Wrapper>
    </Box>
  );
};

export default GlobalWrapper;

const Wrapper = styled(Grid)`
  &&&& {
    width: 100%;
    padding: 0 33px;

    ${({ fullWidth }) =>
      !fullWidth &&
      `
      max-width: 1200px;
      margin: auto;
    `}
  }
`;
