import styled from 'styled-components';
import { Grid } from '@mui/material';

import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';

const FollowMeComponent = () => {
  return (
    <StyledGlobalWrapper rowSpacing={6}>
      <Grid item xs={12}>
        <h1>2023</h1>
      </Grid>

      <iframe
        title="follow-my-challenge"
        src="https://www.followmychallenge.com/live/thegoats23/?iframe"
        width="100%"
        height="800px"
        style={{ border: 'none' }}
        allowfullscreen
      />
    </StyledGlobalWrapper>
  );
};

export default FollowMeComponent;

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    margin-top: 90px;
    margin-bottom: 50px;
  }
`;
