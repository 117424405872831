import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Grid } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';

import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';
import Translator from '../../i18n/Translator';
import { getSponsors } from '../../Contentful';

const FooterComponent = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const getParntersList = async () => {
      const partnersList = await getSponsors();

      if (partnersList && partnersList.length) setPartners(partnersList);
    };

    getParntersList();
  }, []);

  return (
    <StyledGlobalWrapper fullWidth rowSpacing={3}>
      <Grid item xs={12}>
        <PartnersContainer container>
          {partners.map(({ brandUrl, logoWhite }) => (
            <PartnersContent key={brandUrl}>
              <a href={brandUrl} target="_blank" rel="noreferrer">
                <img src={logoWhite.src} alt={logoWhite.alt} />
              </a>
            </PartnersContent>
          ))}
        </PartnersContainer>
      </Grid>

      <Grid item xs={12}>
        <FooterMenuContainer container>
          <Grid item>
            <h4>
              <Translator path="footer.contacts" />
            </h4>
            <p>
              <a href="mailto:info@thegoats.cc">info@thegoats.cc</a>
            </p>
          </Grid>
          <Grid item>
            <h4>
              <Translator path="footer.legalPages" />
            </h4>
            <p>
              <Link to="/privacy-policy">
                <Translator path="footer.privacyPolicy" />
              </Link>
            </p>
            <p>
              <Link to="/cookies-policy">
                <Translator path="footer.cookiesPolicy" />
              </Link>
            </p>
            <p>
              <Link to="/refund-policy">
                <Translator path="refundPolicy.title" />
              </Link>
            </p>
          </Grid>
        </FooterMenuContainer>
      </Grid>

      <SocialContainer item xs={12}>
        <span>the GOATS</span>
        <span>|</span>
        <span>Ultra distance & unsupported cycling</span>
        <span>|</span>
        <a href="https://www.instagram.com/thegoats.cc/" target="_blank" rel="noreferrer">
          <InstagramIcon />
        </a>
      </SocialContainer>
    </StyledGlobalWrapper>
  );
};

export default FooterComponent;

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    height: 315px;

    padding-bottom: 20px;

    color: white;
    background-color: black;

    a {
      color: white;
    }
  }
`;

const PartnersContainer = styled.div`
  &&&& {
    display: flex;
    justify-content: space-around;
    gap: 30px;
    flex-wrap: wrap;
  }
`;

const PartnersContent = styled.div`
  &&&& {
    height: 50px;

    @media (max-width: 920px) {
      height: 20px;
    }

    cursor: pointer;

    img {
      height: 100%;
    }
  }
`;

const FooterMenuContainer = styled(Grid)`
  display: flex;
  justify-content: space-around;

  border-top: 1px white solid;
  border-bottom: 1px white solid;

  @media (max-width: 920px) {
    font-size: 14px;
  }

  h4 {
    margin: 15px 0;
  }

  p {
    margin: 5px 0;
    font-weight: 200;
  }

  p:last-child {
    margin-bottom: 10px;
  }
`;

const SocialContainer = styled(Grid)`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 200;

    @media (max-width: 920px) {
      font-size: 14px;
    }
  }
`;
