import styled from 'styled-components';
import { Grid } from '@mui/material';
import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';
import translationData from '../../i18n/locales/pt-pt';
import Translator from '../../i18n/Translator';

const CookiesPolicyComponent = () => {
  const cookies = translationData.translations.cookies.page;

  return (
    <StyledGlobalWrapper rowSpacing={6}>
      <Grid item xs={12}>
        <h1>
          <Translator path="cookies.page.pageTitle" />
        </h1>
      </Grid>
      {Object.entries(cookies)
        .filter(([sectionKey]) => sectionKey !== 'pageTitle')
        .map(([sectionKey, sectionValues]) => (
          <Grid item xs={12} key={sectionKey}>
            <h3>
              <Translator path={`cookies.page.${sectionKey}.title`} />
            </h3>
            {Object.entries(sectionValues)
              .filter(([key]) => key !== 'title')
              .map(([key, value]) => (
                <p key={`${sectionKey}-${key}`}>{value}</p>
              ))}
          </Grid>
        ))}
    </StyledGlobalWrapper>
  );
};

export default CookiesPolicyComponent;

const HEADER_AND_FOOTER_HEIGHT = '415';

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    min-height: calc(100vh - ${HEADER_AND_FOOTER_HEIGHT}px);

    margin-top: 90px;
    margin-bottom: 50px;
  }

  p {
    font-weight: 200;
  }
`;
