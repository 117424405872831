import { Button, Grid } from '@mui/material';
import styled from 'styled-components';
import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';
import Translator from '../../i18n/Translator';

const CookiesPopoverComponent = ({ handleCookieAccepted }) => {
  return (
    <CookiesWrapper>
      <GlobalWrapper>
        <Grid item xs={12}>
          <h3>
            <Translator path="cookies.bar.title" />
          </h3>
        </Grid>
        <Grid item xs={8}>
          <p>
            <Translator path="cookies.bar.details" />
          </p>
        </Grid>
        <ButtoContainer item xs={4}>
          <Button onClick={handleCookieAccepted}>
            <Translator path="cookies.bar.acceptButton" />
          </Button>
        </ButtoContainer>
      </GlobalWrapper>
    </CookiesWrapper>
  );
};

export default CookiesPopoverComponent;

const CookiesWrapper = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 20px 0;

  background-color: black;
  color: white;

  h3 {
    margin-bottom: 0;
  }

  p {
    font-weight: 200;
  }
`;

const ButtoContainer = styled(Grid)`
  &&&& {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 0 auto;
      color: white;
      border: 1px solid white;
    }
  }
`;
