import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Translator from '../../../i18n/Translator';

const FirstSectionComponent = () => {
  const [backgroundImg, setBackgroundImg] = useState(1);
  const [orientation, setOrientation] = useState('landscape');

  useEffect(() => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const isPortrait = viewportWidth < viewportHeight;

    if (isPortrait) {
      setOrientation('portrait');
    }

    const interval = setInterval(() => {
      backgroundImg < 6 ? setBackgroundImg(backgroundImg + 1) : setBackgroundImg(1);
    }, 7500);

    return () => {
      clearInterval(interval);
    };
  }, [backgroundImg]);

  return (
    <FirstBannerWrapper>
      {orientation === 'landscape' && (
        <img
          src={`/images/homepage-banner/banner${backgroundImg}.jpg`}
          alt="torre-serra-da-estrela ultracycling cycling ultradistance gravel"
        />
      )}
      {orientation === 'portrait' && (
        <img
          src={`/images/homepage-banner/banner${backgroundImg}-portrait.jpg`}
          alt="torre-serra-da-estrela ultracycling cycling ultradistance gravel"
        />
      )}
      <ShadowOverlay />
      <FirstBannerContent>
        <h1>the GOATS</h1>
        <h2>
          <Translator path="homePage.firstSection.subTitle" />
        </h2>
      </FirstBannerContent>
      <FirstBannerFooter>
        <KeyboardArrowDownIcon />
      </FirstBannerFooter>
    </FirstBannerWrapper>
  );
};

export default FirstSectionComponent;

const FirstBannerWrapper = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;

  display: grid;
  grid-template-rows: 1fr 1fr 1fr;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    transform: none;
    width: 100%;
    z-index: -2;
  }
`;

const ShadowOverlay = styled.div`
  &&&& {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;

    background-color: black;
    opacity: 0.4;
  }
`;

const FirstBannerContent = styled.div`
  &&&& {
    grid-row-start: 2;
    grid-row-end: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1,
    h2 {
      color: white;
      text-align: center;
      margin: 10px 0;
    }

    h1 {
      font-weight: 400;
      font-size: 82px;
    }

    h2 {
      font-weight: 300;
    }
  }
`;

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `;
}

const FirstBannerFooter = styled.div`
  &&&& {
    grid-row-start: 3;
    grid-row-end: 4;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    svg {
      color: white;
      font-size: 80px;
      animation: ${blinkingEffect} 2s ease infinite;
    }
  }
`;
