import { Fragment, useState } from 'react';
import axios from 'axios';
import { pathOr } from 'ramda';

import { Dialog, Transition } from '@headlessui/react';

import RegistrationFormComponent from './Components/RegistrationForm/RegistrationFormComponent';
import Translator from '../../i18n/Translator';

const getErrorCode = pathOr('', ['response', 'data', 'code']);
const getEmail = pathOr('', ['response', 'data', 'email']);

const Modal = ({ isOpen, closeModal, title = '', message = '' }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{message}</p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}>
                    ok
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const RegistrationsWithStripeComponent = () => {
  let [isOpen, setIsOpen] = useState(false);
  let [modalContent, setModalContent] = useState({});
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const _handleSubmit = async values => {
    setLoading(true);

    try {
      const { data } = await axios.post(process.env.REACT_APP_API_BASE_URL + '/registration/create-checkout-session', values);

      if (data) window.location.href = data;
    } catch (error) {
      const errorCode = getErrorCode(error);

      switch (errorCode) {
        case 'user_already_exist': {
          const wrongEmail = getEmail(error);

          setModalContent({
            title: <Translator path="registrations.form.userAlreadyExistTitle" />,
            message: <Translator path="registrations.form.userAlreadyExistMessage" variables={{ email: wrongEmail }} />,
          });
          openModal();
          break;
        }

        case 'email_invalid': {
          setModalContent({
            title: <Translator path="registrations.form.emailInvalidTitle" />,
            message: <Translator path="registrations.form.emailInvalidMessage" />,
          });
          openModal();
          break;
        }
        default:
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-28 bg-gray-200">
      <RegistrationFormComponent handleSubmit={_handleSubmit} loading={loading} />

      <Modal isOpen={isOpen} closeModal={closeModal} title={modalContent?.title || ''} message={modalContent?.message || ''} />
    </div>
  );
};

export default RegistrationsWithStripeComponent;
