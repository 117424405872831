export const info = {
  info0: {
    title: 'Dates',
    p1: 'October 5th - Accreditation',
    p2: 'October 6th 09:00am - Start',
    p3: 'October 11th, 11:59pm - Time limit',
    p4: "1 month before the race you'll receive the 'Riders Book'",
    p5: "3 to 5 days before you'll receive a video briefing about the race and all its features.",
  },
  info1: {
    title: 'How much does registration cost?',
    p1: 'Solo - 210€',
    p2: 'Pair - 400€',
  },
  info2: {
    title: 'What does the rider kit include?',
    p1: 'Ridersbook (PDF)',
    p2: 'Finisher gift',
    p3: 'Cap with bib number',
    p4: 'Musette cotton bag',
    p5: 'Media content (photos and vídeo after the adventure)',
  },
  info3: {
    title: 'What is the mandatory material?',
    p1: 'Helmet, front and rear lights, and high-visibility vest and survival blanket',
  },
  info4: {
    title: 'Where to find water and food?',
    p1: 'Along the route there are many places with water and food. One of the best things Portugal has to offer is its food.',
  },
  info5: {
    title: 'What about the weather?',
    p1: 'In the week in which the challenge takes place, the meteorological history gives us the information that the maximum temperatures vary between 24~28°C and the minimum temperatures between 12~16°C, but always expect lower temperatures in the places of the altitude above 1000m.',
  },
  info6: {
    title: 'What type of bike and tires are recommended?',
    p1: "It's an off-road event and both bikes (gravel vs. mountain bikes) are suitable. In the case of gravel bikes, we recommend a tire of at least 700x43cc and a gear ratio for climbing of at least 38x46.",
    p2: 'We are sure that it is one of the best ultra-distance challenges you can experience on a bike.',
  },
  info7: {
    title: 'How to get there?',
    p1: 'Portugal is a small country with a good infrastructure network that allows you to reach the Serra da Estrela, where the adventure begins and ends.',
    p2: 'Two major airports are located about 270 km from Penhas da Saúde-Covilhã: Humberto Delgado Airport in Lisbon and Sá Carneiro Airport in the city of Porto.',
    p3: 'About 3 hours separate each of the airports from the GOATS accreditation and the departure point. Whether by car or train (www.cp.pt).',
  },
  info8: {
    title: 'Where to stay?',
    p1: 'We strongly recommend that you arrive a few days before your adventure and even stay a few days after your adventure.',
    p2: 'The Serra da Estrela, as well as all the places you will visit during your adventure, has an extensive hotel infrastructure that has mastered the art of hospitality. A region rich in history and landscapes. With a unique gastronomy that will surely be remembered by your palate.',
    p3: 'Useful links to help you during your stay:',
    link1: 'http://www.centerofportugal.com',
    link2: 'http://www.aldeiashistoricasdeportugal.com',
    link3: 'http://www.turismodocentro.pt',
    link4: 'http://www.visiteserradaestrela.pt',
    link5: 'http://www.geoparkestrela.pt',
    link6: 'http://www.visitportugal.com',
    link7: 'http://www.aldeiasdoxisto.pt',
  },
};
