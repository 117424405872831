import styled from 'styled-components';

import { Grid } from '@mui/material';
import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';
import Translator from '../../i18n/Translator';

const RefundPolicyComponent = () => {
  return (
    <StyledGlobalWrapper rowSpacing={6}>
      <Grid item xs={12}>
        <h1>
          <Translator path="refundPolicy.title" />
        </h1>
      </Grid>
      <Grid item xs={12}>
        <p>
          <Translator path="refundPolicy.p1" />
        </p>
        <p>
          - <Translator path="refundPolicy.b1" />
        </p>
        <p>
          - <Translator path="refundPolicy.b2" />
        </p>
        <p>
          <Translator path="refundPolicy.p2" />
        </p>
        <p>
          <Translator path="refundPolicy.p3" />
        </p>
        <p>
          <Translator path="refundPolicy.p4" />
        </p>
        <p>
          <Translator path="refundPolicy.p5" /> <a href="mailto:info@thegoats.cc">info@thegoats.cc</a>
        </p>
      </Grid>
    </StyledGlobalWrapper>
  );
};

export default RefundPolicyComponent;

const HEADER_AND_FOOTER_HEIGHT = '415';

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    min-height: calc(100vh - ${HEADER_AND_FOOTER_HEIGHT}px);
    margin-top: 90px;
    margin-bottom: 50px;

    p {
      font-weight: 200;

      a {
        color: black;
      }
    }
  }
`;
