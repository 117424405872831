import { useState, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Grid } from '@mui/material';

import Translator from '../../i18n/Translator';
import { getSponsors } from '../../Contentful';

import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';

const DesktopPartner = ({ title, description, site, logoWhite, logoBlack, imageRightSide = false }) => (
  <Row container xs={12} className={classNames(!imageRightSide && 'bg-black text-white')}>
    {!imageRightSide && (
      <Grid item xs={4}>
        <a href={site} target="_blank" rel="noreferrer">
          <img src={logoWhite.src} alt={logoWhite.alt} />
        </a>
      </Grid>
    )}
    <Grid item xs={8}>
      <h2 className={classNames('text-xl')}>{title}</h2>
      {parse(description)}
    </Grid>
    {imageRightSide && (
      <RightImg item xs={4}>
        <a href={site} target="_blank" rel="noreferrer">
          <img src={logoBlack.src} alt={logoBlack.alt} />
        </a>
      </RightImg>
    )}
  </Row>
);

const MobilePartner = ({ title, description, site, logoBlack }) => (
  <>
    <Grid item xs={12}>
      <a href={site} target="_blank" rel="noreferrer">
        <img src={logoBlack.src} alt={logoBlack.alt} />
      </a>
    </Grid>
    <DescriptionContainer item xs={12}>
      <h2 className={classNames('text-xl')}>{title}</h2>
      {parse(description)}
    </DescriptionContainer>
  </>
);

const PartnersComponent = () => {
  const [partners, setPartners] = useState([]);

  const { i18n } = useTranslation();
  const selectedLanguage = i18n.language;

  const isPortugueseSelected = selectedLanguage === 'pt-PT';

  useEffect(() => {
    const getParntersList = async () => {
      const partnersList = await getSponsors();

      if (partnersList && partnersList.length) setPartners(partnersList);
    };

    getParntersList();
  }, []);

  return (
    <>
      <DesktopGlobalWrapper rowSpacing={8}>
        <Grid item xs={12}>
          <h1 className={classNames('text-4xl')}>
            <Translator path="partners.pageTitle" />
          </h1>
        </Grid>

        {partners.map(({ name, descriptionEn, descriptionPt, brandUrl, logoWhite, logoBlack }, idx) => (
          <DesktopPartner
            key={name}
            title={name}
            description={isPortugueseSelected ? descriptionPt : descriptionEn}
            site={brandUrl}
            logoBlack={logoBlack}
            logoWhite={logoWhite}
            imageRightSide={idx % 2 === 0}
          />
        ))}
      </DesktopGlobalWrapper>

      <MobileGlobalWrapper rowSpacing={8}>
        <Grid item xs={12}>
          <h1 className={classNames('text-4xl')}>
            <Translator path="partners.pageTitle" />
          </h1>
        </Grid>

        {partners.map(({ name, descriptionEn, descriptionPt, brandUrl, logoBlack }) => (
          <MobilePartner
            key={name}
            title={name}
            description={isPortugueseSelected ? descriptionPt : descriptionEn}
            site={brandUrl}
            logoBlack={logoBlack}
          />
        ))}
      </MobileGlobalWrapper>
    </>
  );
};

export default PartnersComponent;

const DesktopGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    @media (max-width: 920px) {
      display: none;
    }

    margin-top: 90px;
    margin-bottom: 50px;

    img:not(.ivznh) {
      width: 90%;
    }

    img.ivznh {
      width: 40%;
    }
  }

  p {
    font-weight: 200;
  }
`;

const MobileGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    @media (min-width: 921px) {
      display: none;
    }

    margin-top: 90px;
    margin-bottom: 50px;

    img {
      width: 100%;
    }

    a {
      display: flex;
      justify-content: center;
    }

    img.ivznh {
      width: 50%;
    }

    P {
      font-weight: 200;
    }
  }
`;

const RightImg = styled(Grid)`
  &&&& {
    a {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const DescriptionContainer = styled(Grid)`
  &&&& {
    border-bottom: 2px solid;
    margin-bottom: 20px;
  }
`;

const Row = styled(Grid)`
  &&&& {
    align-items: center;
    margin-top: 64px;
    padding: 20px 0;

    a {
      display: flex;
      justify-content: center;
    }
  }
`;
