import { useState } from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';
import { translationData } from '../../i18n/locales/pt-pt';
import Translator from '../../i18n/Translator';

const isAPoint = item => !['title', 'link1', 'link2', 'link3', 'link4', 'link5', 'link6', 'link7'].includes(item);

const isALink = item => ['link1', 'link2', 'link3', 'link4', 'link5', 'link6', 'link7'].includes(item);

function InfoComponent() {
  const [infoOpen, setInfoOpen] = useState('info0');
  const { info } = translationData.translations || {};

  return (
    <StyledGlobalWrapper>
      <Grid item xs={12}>
        <h1 className="text-4xl mb-5">Info</h1>
      </Grid>
      {Object.entries(info).map(([infoKey, i]) => (
        <Grid key={infoKey} item xs={12}>
          <Title onClick={() => (infoOpen === infoKey ? setInfoOpen('') : setInfoOpen(infoKey))}>
            <Translator path={`info.${infoKey}.title`} />{' '}
            {infoOpen === infoKey ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Title>

          <PointsWrapper open={infoOpen === infoKey}>
            {Object.keys(i)
              .filter(pointKey => isAPoint(pointKey))
              .map(pointKey => (
                <Point key={`${pointKey}-${i}`}>
                  -
                  <Translator path={`info.${infoKey}.${pointKey}`} />
                </Point>
              ))}
            {Object.keys(i)
              .filter(pointKey => isALink(pointKey))
              .map(pointKey => (
                <Point key={`${pointKey}-${i}`} isLink>
                  <a href={Translator({ path: `info.${infoKey}.${pointKey}`, plainText: true })}>
                    <Translator path={`info.${infoKey}.${pointKey}`} />
                  </a>
                </Point>
              ))}
          </PointsWrapper>
        </Grid>
      ))}
    </StyledGlobalWrapper>
  );
}

export default InfoComponent;

const HEADER_AND_FOOTER_HEIGHT = '415';

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    min-height: calc(100vh - ${HEADER_AND_FOOTER_HEIGHT}px);

    margin-top: 90px;
    margin-bottom: 50px;

    h1 {
      margin-top: 69.44px;
    }
  }

  p {
    font-weight: 200;
  }

  a {
    color: black;
  }
`;

const Title = styled.h4`
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 18px;
  margin-top: 1.2rem;
`;

const PointsWrapper = styled.div`
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;

  ${({ open }) =>
    open &&
    `
    max-height: 500px;
    transition: max-height 0.5s ease-in;
  `}
`;

const Point = styled.p`
  margin-top: 0.8rem;

  ${({ isLink }) => isLink && 'padding-left: 25px;'}
`;
