import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import { isNil, omit } from 'ramda';

import TabsComponent from './Tabs';
import IndividualFields from './IndividualFields';
import { pairRegistrationSchema, soloRegistrationSchema } from './schemas';
import { pairInitialValues, soloInitialValues } from './constants';

import TermsCaptchaAndSubmit from './TermsCaptchAndSubmit';
import Translator from '../../../../i18n/Translator';
import { ThreeCircles } from 'react-loader-spinner';

const RegistrationFormComponent = ({ handleSubmit, loading }) => {
  const _handleSubmit = (values, setErrors, category) => {
    if (isNil(values?.user_captcha_input)) return;

    if (validateCaptcha(values.user_captcha_input) == true) {
      handleSubmit({ ...omit(['user_captcha_input', 'termsAndConditionsAccepted'], values), category });
    } else {
      alert('Captcha Does Not Match');
      setErrors({ user_captcha_input: 'Captcha error' });
    }
  };

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, [selectedTab]);

  const loadingRenderer = () => (
    <div
      className={classNames(
        'fixed top-0 right-0 bottom-0 left-0',
        'bg-[#000000ba]',
        'flex items-center justify-center',
        'z-[2000]',
      )}>
      <ThreeCircles
        height="100"
        width="100"
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
    </div>
  );

  const soloTabRenderer = () => {
    return (
      <Formik
        initialValues={soloInitialValues}
        validationSchema={soloRegistrationSchema}
        onSubmit={(values, { setErrors }) => _handleSubmit(values, setErrors, 'solo')}>
        {formik => {
          return (
            <>
              <IndividualFields formikProps={formik} />

              <TermsCaptchaAndSubmit formik={formik} />
            </>
          );
        }}
      </Formik>
    );
  };

  const pairTabRenderer = () => {
    return (
      <Formik
        initialValues={pairInitialValues}
        validationSchema={pairRegistrationSchema}
        onSubmit={(values, { setErrors }) => _handleSubmit(values, setErrors, 'pair')}>
        {formik => {
          return (
            <>
              <div className={classNames('divide-y-2')}>
                <div>
                  <IndividualFields formikProps={formik} />
                </div>
                <div>
                  <IndividualFields
                    formikProps={formik}
                    isFellow={true}
                    preLabel={<Translator path="registrations.form.fellow" />}
                  />
                </div>
              </div>

              <TermsCaptchaAndSubmit formik={formik} />
            </>
          );
        }}
      </Formik>
    );
  };

  const tabs = {
    solo: soloTabRenderer(),
    pair: pairTabRenderer(),
  };

  return (
    <div className={classNames('max-w-[920px] w-full mx-auto px-1 md:px-5')}>
      <h1 className={classNames('text-4xl mb-4')}>Registrations</h1>
      <TabsComponent tabs={tabs} tabChanged={tab => setSelectedTab(tab)} />

      {loading && loadingRenderer()}
    </div>
  );
};

export default RegistrationFormComponent;
