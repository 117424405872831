export const theRoute = {
  pageTitle: 'A Rota',
  intro:
    'Embarca numa jornada circular pela região montanhosa de Portugal, cujo pico mais alto se eleva a 1993m de altitude. Este percurso tem uma ligação íntima com os principais rios portugueses e atravessa um conjunto de seis serras distintas, cada uma repleta de diversidade e singularidade. Esta rota oferece uma experiência emocionante e longe de ser monótona.<br/><br/>Este é um desafio exigente que requer a tua melhor preparação e equipamento. No entanto, ao alcançares o ponto mais alto de Portugal continental e concluíres este desafio, garantimos que te sentirás invencível e capaz de conquistar qualquer coisa.',
  cp1: {
    title: 'CP1 - Gardunha',
    description:
      'Esta é a primeira ascensão significativa que se apresenta no trajeto. Com os seus 9.6km que culminam no pico, correspondendo ao CP1, esta subida proporciona vistas esplêndidas e pode brindar-vos com um dos mais belos pôr do sol que já testemunharam. As vistas para sul e oeste tornam esta árdua subida, repleta de curvas e contracurvas, muito mais gratificante.<br/><br/>Pertence ao conjunto montanhoso denominado por Cordilheira Central, estende-se no sentido Nordeste/Sudoeste e com uma altitude máxima de 1227 metros, na Penha, de onde, segundo o poeta José Régio, se avistam “terras de Espanha e areias de Portugal”.',
  },
  cp2: {
    title: 'CP2 - Lousã',
    description:
      'O CP2 culmina com a majestosa Serra da Lousã, uma serra que se destaca pela sua rica fauna e flora e possui um património inigualável. A aproximação a esta serra é marcada por uma longa ascensão de cerca de 15km, com um sobe e desce suave ao longo da sua crista até ao ponto de controlo, atingindo uma altitude máxima de 1085m.<br/><br/>Esta subida é realizada principalmente por uma estrada de cascalho branco que atravessa o parque eólico da Lousã, chegando quase ao seu cume, e depois segue em direção à aldeia de xisto da Pena, uma das muitas que se encontram nesta serra. As aldeias de xisto são pequenas povoações caracterizadas pelas suas casas construídas em pedra de xisto, formando um arranjo visual muito belo e distinto.',
  },
  cp3: {
    title: 'CP3 - Serra do Caramulo',
    description:
      'A Serra do Caramulo é uma zona de montanha de origem granítica e xistosa. As urzes e a carqueja predominam a sua flora. Esta zona povoada por romanos, ainda se podem encontrar alguns vestígios dessa época, como os trilhos de pedra.<br/><br/>Pode-se apreciar os campos verdes e a beleza das árvores junto à água cristalina dos ribeiros que a atravessa por todos os lados e desfrutar da deslumbrante paisagem enquanto respira um ar realmente puro e saudável. Pode-se subir ao Caramulinho, o ponto mais alto da Serra com 1076.57 metros, onde se avista o mar e a Serra da Estrela em dias sem nebulosidade.<br/><br/>A paisagem da Serra do Caramulo é um monumento à natureza e o ar puro que ali se respira convida à exploração de todos os recantos, por mais escondidos que sejam. É um lugar cheio de surpresas, de vistas magníficas e de desafios estimulantes. Um cenário perfeito e repleto de estradões de gravilha.',
  },
  cp4: {
    title: 'CP4 - Serra da Estrela',
    description:
      'Onde termina a tua aventura. No ponto mais alto de Portugal continental. E acredita que tem um significado tremendo e para sempre perdura na tua memória.<br/><br/>O parque natural da Estrela é a maior área protegida portuguesa e situa-se no maciço montanhoso central, num alto planalto inclinado para Nordeste profundamente recortado pelos vales dos rios e ribeiros que aqui nascem, como o Mondego e o Zêzere.<br/><br/>A paisagem é marcada pelas fragas, rochedos e penhascos, alguns dos quais assumem formas que deram origem a denominações populares como a "Cabeça da Velha" e os "Cântaros" (gordo, magro e raso).<br/><br/>A pastorícia como a transumância são parte desta serra pelas suas vastas áreas de pastagem, assim como o cão da Serra da Estrela, uma raça de cães possantes, mas amistosos e resistentes às baixas temperaturas que ajudam os pastores no seu trabalho.<br/><br/>Os primeiros 35km e os últimos 130km acontecerão no núcleo montanhoso deste vasto parque natural e serão incríveis para a vossa vista.',
  },
};
