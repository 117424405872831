import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import Translator from '../../../../i18n/Translator';
import { LoadCanvasTemplate } from 'react-simple-captcha';

const TermsCaptchaAndSubmit = ({ formik }) => {
  const { dirty, isValid, handleSubmit } = formik;

  return (
    <>
      <div className={classNames('mb-4')}>
        <label>
          <Field type="checkbox" name="termsAndConditionsAccepted" className="mr-2" />
          <Translator path={'registrations.form.terms'} />
        </label>
        <ErrorMessage name="termsAndConditionsAccepted" component="span" className="text-red-600" />
      </div>

      <div className={classNames('mb-4')}>
        <LoadCanvasTemplate />

        <Field
          className={classNames('border rounded-md mt-2 h-10 px-2')}
          placeholder="Enter Captcha Value"
          id="user_captcha_input"
          name="user_captcha_input"
          type="text"
        />
        <ErrorMessage name="user_captcha_input" component="span" className="text-red-600" />
      </div>

      <button
        type="submit"
        className={classNames(
          'rounded py-1 px-5 bg-gray-200 sm:h-10',
          !(dirty && isValid) ? 'text-gray-400 cursor-not-allowed' : 'text-black hover:bg-gray-300',
        )}
        onClick={handleSubmit}
        disabled={!(dirty && isValid)}>
        <Translator path={`registrations.form.goToPayment`} />
      </button>
    </>
  );
};

export default TermsCaptchaAndSubmit;
