export const form = {
  required: 'Required field',
  name: 'Name',
  email: 'Email',
  validEmailRequired: 'A valid email is required',
  phone: 'Phone',
  nationality: 'Nacionality',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  docType: 'Identification Document',
  docTypeNational: 'National Document',
  docTypePassport: 'Passport',
  docNumber: 'Document Number',
  dateOfBirth: 'Birth Date',
  minAgeRequired: 'You must be of legal age to register. If this is not the case, please contact us at info@thegoats.cc.',
  address: 'Where do you live? (Country, city)',
  bicycle: 'Which kind of bike will you use? (mtb or gravel)',
  wayOfTravel: 'How you plan to travel to the place of departure?',
  bikepackingCompletedRaces: 'How many bikepacking races have you done?',
  favoriteSong: `Favorite song (or the one you're listening to at the moment)?`,
  category: 'Category',
  solo: 'Solo - 210€',
  pair: 'Pair - 400€',
  userAlreadyExistTitle: 'User already registered',
  userAlreadyExistMessage:
    'The e-mail {{email}} is already registered. Please check that the email address is correct. If the problem persists, send us an e-mail to info@thegoats.cc!',
  emailInvalidTitle: 'Invalid e-mail',
  emailInvalidMessage:
    'The e-mail you tied to register is not valid. Please check that the email address is correct. If the problem persists, send us an e-mail to info@thegoats.cc!',
  terms:
    'I accept the terms and conditions, as well as confirm that I have read and accepted the Manifest available on this site.',
  termsRequired: 'You need to accept the terms and conditions',
  goToPayment: 'Proceed to checkout!',
  registrationConfirmed: "Welcome to the GOATS!<br/>Now it's official: you are in. Your registration is confirmed. See you soon!",
  registrationError:
    'Something went wrong during the registration process.<br/>In principle, no fee has been charged. In any case, we ask you to confirm and try registering again. If the problem persists, please contact us at info@thegoats.cc.',
  fellow: 'Fellow - ',
};
