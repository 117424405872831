import { form } from './Pt/form';
import { info } from './Pt/info';
import { theRoute } from './Pt/theRoute';
import { partners } from './Pt/partners';

export const translationData = {
  translations: {
    modal: {
      ok: 'Compreendo!',
    },
    menu: {
      item1: { label: 'the Goats' },
      item2: {
        label: 'A Rota',
      },
      item3: {
        label: 'Manifesto',
      },
      item4: {
        label: 'Info',
      },
      item5: {
        label: 'Contactos',
      },
      item6: {
        label: 'Parceiros',
      },
      item7: {
        label: 'Edições',
      },
    },
    homePage: {
      firstSection: {
        subTitle: 'Ciclismo de ultra distância sem suporte',
      },
      secondSection: {
        title: 'O desafio',
        date: '5 de Outubro - 11 de Outubro',
        local: 'Serra da Estrela - Portugal',
        findOutMore: 'Mais informações',
        registrations: 'Inscreve-te já!',
      },
      highlightsSection: {
        photo1: {
          title: 'Malcata',
          description:
            'Serra da Malcata, uma reserva natural e a terra do Lince Ibérico. Uma serra raiana de cabeços arredondados que se estende até à Serra de Gata em Espanha. No seu sopé podemos contar com bosques ripícolas do Côa e da ribeira da Meimoa que proporcionam uma viagem deslumbrante e que nos faz esquecer qualquer cansaço que possamos já transportar. Nesta Serra pode ser visto um fantástico nascer do dia ou um esplendoroso pôr do sol.',
        },
        photo2: {
          title: 'Monsanto',
          description:
            'Um lugar tão icónico que se destaca numa elevação rochosa peculiar, com a sua Aldeia tão simbioticamente entrosada com o meio envolvente. A parte mais antiga está no ponto mais alto, onde os Templários construíram uma cerca com uma torre de menagem. Uma aldeia com uma história tão rica que remonta ao tempo da ocupação visigótica, árabe e romana e inclusivamente vestígios de um castro lusitano.',
        },
        photo3: {
          title: 'Gardunha',
          description:
            'Ao passar a Aldeia de Castelo Novo (Aldeia Histórica) inicia-se uma longa subida de gravel perfeito que te levará ao topo e que coincide com o ponto mais alto a 1227 metros da serra da Gardunha, na Penha, de onde, segundo o poeta José Régio, se avistam “terras de Espanha e areias de Portugal”. Nesta Serra, no seu topo, a norte poderás contemplar a Serra da Estrela, onde começaste e onde te tornarás GOAT. Assenta numa região que produz um fruto delicioso, a cereja. Daqui seguimos para cruzar pela segunda vez, o Rio Zêzere, que nasce na Serra da Estrela a 1900m de altitude descendo pelo Vale Glaciar, tal como o fizeste à partida desta aventura.',
        },
        photo4: {
          title: 'Serra da Lousã',
          description:
            'De um verde incrível, esta serra cheia de mística oferece-nos muito. Muito conhecida pelas suas Aldeias de Xisto como o Candal, Gondramaz, Talasnal, Cerdeira e outras mais. Integra o ramo norte da Cordilheira Central, constituída pelas serras da Estrela, Açor e Lousã, fazendo também parte do Sistema Montejunto-Estrela. E daqui segues para norte em direção ao Buçaco já com muitos, mas bons quilómetros nas pernas.',
        },
        photo5: {
          title: 'Luso',
          description:
            'Uma das maiores reservas de biodiversidade de Portugal, onde existem cerca de 250 espécies de arbustos e árvores, muitas delas seculares. E com um dos palácios (Hotel) mais lindos de Portugal, estamos na mata Nacional do Buçaco. Impossível descrever em palavras, a beleza dos jardins do Palácio aos quais vais aceder pelas portas de Coimbra, sempre abertas para te receber. E daqui para a frente, criarás uma enorme afinidade com o Rio do Mondego, o maior Rio Exclusivamente Português e que nasce exatamente na Serra da Estrela, de onde partiste e onde chegarás, tal como o Rio Zêzere.',
        },
        photo6: {
          title: 'Torre, Serra da Estrela',
          description:
            'E eis que chegaste à majestosa Serra da Estrela. A última grande dificuldade está diante de ti, mas estás perto de tornar-te GOAT. Longas subidas, mas com vistas de cortar a respiração, contrastes de cores, de vegetação e um ar tão puro que te farão sentir forte para esta última dificuldade. Os últimos quilómetros surgem num terreno mais fácil (asfalto) com um propósito de poderes contemplar as últimas vistas ou quiçá um céu tão estrelado e deslumbrante que jamais esquecerás e, poderes absorver retrospetivamente por tudo o que passaste e chegares ao alto da Torre e podermos-te dar um abraço e entregar-te o símbolo da tua CONQUISTA.',
        },
      },
    },
    footer: {
      contacts: 'Contactos',
      legalPages: 'Páginas legais',
      legalNotice: 'Avisos legais',
      privacyPolicy: 'Política de privacidade',
      cookiesPolicy: 'Política de Cookies',
    },
    cookies: {
      bar: {
        title: 'Cookies',
        details:
          'Este site utiliza cookies e outras tecnologias de rastreamento para ajudar na sua navegação e na nossa capacidade de fornecer feedback, analisar o uso do nosso site e ajudar a fornecer informações promocionais sobre nossos serviços e produtos.',
        acceptButton: 'Aceito',
      },
      page: {
        pageTitle: 'Política de Cookies',
        section1: {
          title: 'O que são cookies',
          p1: 'Os cookies são pequenos ficheiros de texto que um site, quando visitado, coloca no computador do utilizador ou no seu dispositivo móvel, através do navegador de internet (browser). A colocação de cookies ajuda o site a reconhecer o dispositivo numa próxima visita sendo por vezes necessários para um bom funcionamento. Usamos o termo cookies nesta política para referir todos os ficheiros que recolhem informações desta forma.',
        },
        section2: {
          title: 'Como utilizamos os cookies',
          p1: 'Os cookies utilizados neste website da The Goats são os seguintes:',
          p2: '1. Cookies estritamente necessários e de Funcionalidade:',
          p3: '- Para viabilizar ou optimizar as funcionalidades oferecidas pelo website e melhorar a experiência de navegação do utilizador.',
          p4: '2. Cookies Analíticos:',
          p5: '- Instalados por ferramentas geridas por terceiros (Google Analytics, etc.) para acompanhar a qualidade da experiência do utilizador na navegação neste website.',
          p6: '3. Cookies de terceiros:',
          p7: '- Facebook, YouTube, Vimeo, OpenStreetMap e outros instrumentos geridos por terceiros podem instalar cookies para fins de partilha de conteúdos nas redes sociais ou de elaboração de estatísticas de consulta. A presença, o número e o estatuto destes cookies podem depender da forma de utilização das plataformas em questão antes ou durante uma visita a este website. É útil verificar qual a política de gestão dos cookies dessas redes sociais nos respetivos websites. Por vezes utilizamos serviços web de terceiros para mostrar conteúdos dentro do nosso website, por exemplo, para mostrar mapas ou vídeo. À semelhança dos botões de partilha para redes sociais, não podemos evitar que estes websites ou domínios externos recolham informações acerca da utilização feita destes conteúdos incorporados.',
        },
        section3: {
          title: 'Para que servem os cookies',
          p1: 'Você pode a qualquer momento optar por desativar todos ou parte dos cookies. O navegador também pode ser configurado para o notificar sobre cookies disponibilizados no ecrâ e pedir para aceitá-los ou não (caso a caso ou na íntegra). No entanto, lembramos que a desativação de todos os cookies impedirá que você use o website em condições normais, exceto para funções básicas.',
        },
        section4: {
          title: 'Recusar um cookie através do seu navegador',
          p1: 'Você pode a qualquer momento optar por desativar todos ou parte dos cookies. O navegador também pode ser configurado para o notificar sobre cookies disponibilizados no ecrâ e pedir para aceitá-los ou não (caso a caso ou na íntegra). No entanto, lembramos que a desativação de todos os cookies impedirá que você use o website em condições normais, exceto para funções básicas.',
        },
      },
    },
    theRoute: theRoute,
    info: info,
    refundPolicy: {
      title: 'Política de Reembolso',
      p1: 'A nossa Política de Reembolsos como a organização, desenvolve-se naquilo que este tipo de eventos permite devido aos compromissos com fornecedores, staff/media, logística e outras questões principais necessárias:',
      b1: 'Devolução até 15/AGO/2024: 50% do valor da inscrição.',
      b2: 'Não haverá reembolso após 15/AGO/2024.',
      p2: 'Se o evento for cancelado como resultado de desastre natural, guerra, intervenções legais, acidentes graves durante o evento, pandemias, bloqueios ou outras causas semelhantes, o “the GOATS” reagendará o evento. Neste caso os participantes terão duas opções: primeiro manter a vaga para a nova data, e segundo -por tempo limitado- um reembolso de 80% do valor da inscrição, caso o cancelamento pelas situações descritas.',
      p3: 'Em caso de condições meteorológicas adversas ou de risco, a rota será parcialmente alterada. Após o início do evento, não há lugar a reembolso se for cancelado, adiado ou reencaminhado.',
      p4: 'Qualquer mudança de categoria proposta pelos participantes terá um custo de 20€/participante. Nenhuma alteração permitida após 15/AGO/2024.',
      p5: 'Se você tiver alguma dúvida, entre em contato conosco em',
    },
    partners: partners,
    manifesto: {
      rule1: {
        title: 'NÃO DEIXES RASTO',
        description1:
          'As únicas marcas que deves deixar na tua passagem, são as dos teus pneus. Respeita a natureza. Passarás por alguns ecossistemas frágeis e áreas protegidas, portanto deixa a natureza inalterada.',
      },
      rule2: {
        title: 'SEGURANÇA | RESPONSABILIDADE',
        description1:
          'The GOATS utiliza vias abertas ao trânsito, devendo-se sempre conhecer e cumprir as leis locais e a comunidade. A segurança sempre acima de tudo e a rota definida pela organização é ciclável e no respeito das regras locais.',
        description2:
          'Capacete, luzes dianteiras e traseiras, bem como colete refletivo à noite são obrigatórios. Sejam visíveis aos restantes utentes das vias comuns.',
      },
      rule3: {
        title: 'APENAS TU (HONESTIDADE E HONRA)',
        description1:
          'Apenas tu, a tua força e a tua bicicleta (E-Bikes não permitidas), sem ajudas. O “draft” circular imediatamente atrás de outro participante não é permitido, a menos que participes em dupla ou durante fases neutralizadas, indicadas pela organização em tempo próprio. Circularem lado a lado é permitido.',
        description2:
          'Pode sair do percurso em caso de emergência, mas deves reentrar no ponto exato de saída e continuar a rota.',
      },
      rule4: {
        title: 'SÊ AUTOSSUFICIENTE',
        description1:
          'Alimentação, bebidas e pertences devem ser transportados por cada participante ou adquiridos durante o percurso. Você é responsável pela sua própria segurança e logística.',
        description2:
          'Não é permitido reservar alojamento antes do evento. Equipas de apoio são proibidas, mas ajuda inesperada e não solicitada durante o percurso é permitido em situações de necessidade como, problemas mecânicos.',
        description3:
          'A dupla é considerada como um participante solo: Podem apenas partilhar os recursos que necessitem ou usem, entre si.',
      },
      rule5: {
        title: 'SEGUE A ROTA',
        description1:
          'Deves seguir o percurso que fornecemos. Se existirem bloqueios no percurso, por algo inesperado (por exemplo, obras, desmoronamentos, árvores no caminho, etc.), você pode sair do percurso, mas deve retornar a ele o mais rápido possível.',
        description2: 'Atalhos intencionais custarão implicam em penalidades de tempo.',
        description3:
          'Também podes sair do percurso temporariamente (para comer, descansar, etc.), mas deves retornar ao mesmo ponto de onde paraste para continuar o desafio. Em caso de emergência médica ou mecânica, usa o meio de transporte que for mais conveniente e retorna ao mesmo ponto para continuar a rota.',
      },
      rule6: {
        title: 'PONTUALIDADE',
        description1: 'Respeita as horas da acreditação, briefing e início do desafio.',
        description2:
          'Se não terminares o percurso antes do tempo limite, serás considerado DNF. Não podes esperar qualquer assistência ou suporte oficial o tempo limite, mas podes continuar a viagem: basta devolver o “tracker gps”, relaxar e aproveitar.',
        description3:
          'Em duplas, se um dos participantes desistir, o par será pontuado como DNF, mas o elemento que decidir manter-se no desafio, pode continuar e será pontuado como “finisher” (fora da categoria).',
      },
      rule7: {
        title: 'CONTROLA O TEU “TRACKER”',
        description1:
          'O rastreamento por satélite é apenas um serviço para acompanhar os participantes online. Você é responsável pelo uso adequado do rastreador gps. Desligar o rastreador ou longos períodos de inatividade sem reportar à organização resultará em penalidades.',
        description2: 'Se a dupla estiver separada por qualquer motivo, o rastreador deve ser usado pelo último.',
        description3: 'Um depósito reembolsável contra perdas ou danos será exigido para cada rastreador gps.',
      },
      rule8: {
        title: 'RESPEITO',
        description1:
          'Por favor, seja simpático com o staff, eles trabalharão e investirão o seu tempo e esforço para apoiar esta aventura. De igual forma assim o sejam com todos os outros participantes e locais.',
        description2: 'Siga os conselhos do staff, será em prol da vossa segurança e para o bom desenrolar da aventura.',
      },
      rule9: {
        title: 'SEGURO',
        description1:
          'É obrigatório um seguro válido de responsabilidade civil, morte, acidente pessoais durante o evento, cobrindo emergência de extração e seguro de repatriação.',
        description2:
          'Esteja ciente de que toda a responsabilidade recai sobre você. No entanto a organização terá um seguro de acidentes pessoais com intuito de reforço do seguro que cada um deverá ter.',
      },
      rule10: {
        title: 'SEJA RESPONSAVEL E RAZOAVEL',
        description1:
          'Cumpre com todas as regras aqui descritas, respeita o teu corpo e sê razoável e racional nas tuas decisões não perdendo a noção da dimensão do desafio e da gestão do teu esforço, não exagerando na privação do sono porque desta forma estarás mais exposto a acidentes por falta de concentração e reflexos.',
        description2:
          'Estuda a rota e planeia a tua aventura cuidadosamente, pensando em todos os elementos que possam contribuir para o teu sucesso e para uma experiência memorável.',
      },
    },
    contacts: {
      title: 'Contactos',
      subTitle: 'Estamos sempre disponíveis para responder às tuas questões e ouvir as tuas ideias',
      subTitle2: 'Podes contactar-nos através de',
      mobile: 'Telemóvel',
    },
    privacyPolicy: {
      pageTitle: 'Política de Privacidade',
      p1: 'Na UBE – Unsupported bike events, associação estamos conscientes da importância para si da utilização que fazemos dos seus dados de contacto pessoais ou profissionais e da forma como os gerimos. Esta Política de Privacidade e Proteção de Dados pretende ajudá-lo a compreender quais os dados que recolhemos e com que finalidade os tratamos, bem como os nossos esforços para os proteger com as devidas garantias e de acordo com a regulamentação em vigor.',
      p2: "Os dados pessoais que você fornecer através deste site localizado na URL https://thegoats.cc/ (doravante, o 'site') serão tratados conforme descrito detalhadamente abaixo:",
      question1: 'Quem é o responsável pelo tratamento dos seus dados?',
      p3: 'De acordo com a Lei 34/2002, sobre Serviços da Sociedade da Informação e Comércio Eletrônico (doravante, LSSI), Regulamento Geral (UE) 2016/679 sobre Proteção de Dados (doravante, RGPD) e a Lei Orgânica 3/2018 de Proteção de Dados Pessoais Dados e garantia de direitos digitais (doravante, LOPDgdd), informamos que os dados pessoais fornecidos no preenchimento e envio dos formulários publicados no site serão incorporados aos arquivos que compõem o registro das atividades da UBE-Unsupported Bike Events, Associação , com NIPC 517392607 e sede em R. Condes de Atouguia, 2488 A-B, Sesimbra- Portugal',
      question2: 'Que dados do utilizador iremos processar?',
      p4: 'UBE-Unsupported Bike Events tratará das seguintes categorias de dados do utilizador:',
      p5: 'Dados de identificação: nome e apelido.',
      p6: 'Informações de contato pessoal ou profissional: endereço postal, endereço de e-mail, telefone.',
      p7: 'Outros dados: dados fornecidos pelos próprios interessados na promoção de relações comerciais e de interesse mútuo.',
      question3: 'Com que finalidade e base de legitimidade tratamos os seus dados?',
      p8: 'Gestão dos pedidos de contacto e informação recebida por e-mail e/ou formulário web. Base legal de legitimação: Consentimento ou interesse legítimo do interessado (Art. 6.1 a) ef) RGPD).',
      p9: 'Gerir a relação contratual ou comercial estabelecida entre o Utilizador UBE-Unsupported Bike Events e executar o contrato que vincula as Partes. Base legal de legitimação: Celebração de contrato ou relação comercial e empresarial (Art. 6.1 b) RGPD).',
      p10: 'Cumprimento das obrigações vinculativas previstas na Lei. Base legal de legitimação: Cumprimento de obrigação legal (Art. 6.1 c) RGPD).',
      p11: 'Gestão da relação entre o Utilizador, como contacto profissional e/ou representante de pessoa coletiva, e o UBE-Unsupported Bike Events, incluindo o envio de comunicações comerciais, notícias de interesse, eventos, conferências, corridas e outras informações sobre serviços similares aos contratados . originalmente. Base legal de legitimação: Interesse legítimo e mútuo das Partes (Art. 6.1 f) RGPD).',
      p12: 'Processamento do envio da Newsletter, publicações e outros boletins informativos aos seus subscritores. Base legal de legitimação: Consentimento expresso (Art. 6.1 a) RGPD).',
      question4: 'A quem iremos comunicar os seus dados pessoais?',
      p13: 'UBE-Unsupported Bike Events apenas comunicará dados pessoais a:',
      p14: 'Aqueles terceiros, organismos públicos e instituições da Administração Geral do Estado, das administrações regionais e locais, incluindo os órgãos jurisdicionais a quem esteja legalmente obrigado a fornecê-los.',
      p15: 'Sociedades colaboradoras e/ou parceiros para que possam gerir a relação contratual consigo.',
      p16: 'Aos prestadores de serviços vinculados contratualmente ao UBE-Unsupported Bike Events, na qualidade de responsáveis pelo tratamento que tratarão os seus dados estritamente seguindo as instruções de privacidade da informação e com as garantias necessárias, para efeitos do tratamento.',
      question5: 'Por quanto tempo manteremos seus dados?',
      p17: 'Os dados pessoais fornecidos para cumprir as obrigações decorrentes da relação contratual entre o Utilizador e o UBE-Unsupported Bike Events serão conservados durante todo o período de vigência da relação comercial; e uma vez terminada a relação comercial, os dados serão conservados durante o prazo de prescrição das ações que possam decorrer da relação mantida com o Utilizador.',
      p18: 'No entanto, se tiver fornecido os seus dados com base num interesse legítimo mútuo com a perspetiva de promover futuras relações comerciais, os seus dados serão conservados até ao momento em que manifeste a sua vontade de revogar o referido consentimento.',
      question6: 'Que medidas de segurança serão aplicadas aos seus dados pessoais?',
      p19: 'O tratamento dos dados pessoais fornecidos será efetuado adotando as medidas técnicas e organizativas necessárias para evitar a perda, uso indevido, alteração e acesso não autorizado aos mesmos, tendo em conta o estado da tecnologia, a natureza dos dados e a análise de risco realizado.',
      question7: 'Como pode exercer os seus direitos em relação aos seus dados pessoais?',
      p20: 'Para revogar os consentimentos concedidos, bem como exercer os direitos de acesso, retificação, apagamento, oposição, limitação e portabilidade, bem como não ser objeto de decisão individual automatizada, poderá contactar-nos - info@thegoats.cc',
    },
    registrations: {
      pageTitle: 'Inscrições',
      announcement: 'As inscrições encontram-se encerradas.',
      form: form,
    },
  },
};

export default translationData;
