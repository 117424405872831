export const theRoute = {
  pageTitle: 'The Route',
  intro:
    "Embark on a circular journey through Portugal's mountainous region, whose highest peak is 1993 meters above sea level. This route has an intimate connection with Portugal's main rivers and crosses a set of six distinct mountain ranges, each full of diversity and uniqueness. This route offers an exciting and far from monotonous experience.<br/><br/>This is a demanding challenge that requires your best preparation and equipment. However, when you reach the highest point in mainland Portugal and complete this challenge, we guarantee that you will feel invincible and capable of conquering anything.",
  cp1: {
    title: 'CP1 - Gardunha',
    description:
      "This is the first significant ascent on the route. With its 9.6 kilometres culminating at the peak, corresponding to CP1, this climb offers splendid views and may give you one of the most beautiful sunsets you've ever witnessed. The views to the south and west make this arduous climb, with it’s hairpins, much more rewarding.<br/><br/>It belongs to the mountain range known as the Cordillera Central, extends in a north-easterly/south-westerly direction and has a maximum altitude of 1227 metres, at Penha, from where, according to the poet José Régio, you can see 'lands of Spain and sands of Portugal'.",
  },
  cp2: {
    title: 'CP2 - Lousã',
    description:
      'CP2 culminates in the majestic Serra da Lousã, a mountain range that stands out for its rich fauna and flora and unrivalled heritage. The approach to this mountain is marked by a long ascent of around 15km, with a gentle climb and descent along its ridge to the control point, reaching a maximum altitude of 1085m.<br/><br/>This ascent is mainly on a white gravel road that crosses the Lousã wind farm, almost reaching its summit, and then heads towards the schist village of Pena, one of the many that can be found in this mountain range.<br/><br/>The schist villages are small towns characterised by their houses built from schist stone, forming a very beautiful and distinctive visual arrangement.',
  },
  cp3: {
    title: 'CP3 - Serra do Caramulo',
    description:
      "The Serra do Caramulo is a mountain area of granite and schist origin. Heather and carqueja predominate its flora. This area was populated by Romans and you can still find some vestiges of that era, such as the stone tracks.<br/><br/>You can appreciate the green fields and the beauty of the trees next to the crystal-clear water of the streams that cross it on all sides and enjoy the breathtaking scenery while breathing truly pure and healthy air. You can climb Caramulinho, the highest point in the Serra at 1076.57 metres, where you can see the sea and the Serra da Estrela on cloudless days.<br/><br/>The landscape of the Serra do Caramulo is a monument to nature and the pure air you breathe there invites you to explore all the nooks and crannies, however hidden they may be. It's a place full of surprises, magnificent views and stimulating challenges. A perfect setting full of gravel roads.",
  },
  cp4: {
    title: 'CP4 - Serra da Estrela',
    description:
      "Where your adventure ends. At the highest point in mainland Portugal. And believe me, it has tremendous significance and will remain in your memory forever.<br/><br/>The Estrela Natural Park is Portugal's largest protected area and is located in the central mountain massif, on a high plateau sloping down to the north-east, deeply indented by the valleys of the rivers and streams that originate here, such as the Mondego and the Zêzere.<br/><br/>The landscape is characterised by crags, rocks and cliffs, some of which take on shapes that have given rise to popular names such as 'Cabeça da Velha' and 'Cântaros' (fat, thin and shallow).<br/><br/>Pastoralism and transhumance are part of this mountain range due to its vast grazing areas, as well as the Serra da Estrela dog, a breed of powerful but friendly dogs resistant to low temperatures that help shepherds in their work.<br/><br/>The first 35km and the last 130km will take place in the mountainous core of this vast natural park and will be incredible for your view.",
  },
};
