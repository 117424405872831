import styled from 'styled-components';

import InstagramIcon from '@mui/icons-material/Instagram';

const InstaFeedComponent = () => {
  return (
    <Wrapper>
      <Title>
        <a href="https://www.instagram.com/thegoats.cc/" target="_blank" rel="noreferrer">
          <InstagramIcon /> thegoats.cc
        </a>
      </Title>
      <figure data-behold-id="BpssAWikpSvpt0MkGJbd"></figure>
    </Wrapper>
  );
};

export default InstaFeedComponent;

const Wrapper = styled.div`
  padding: 50px 0;
  background-color: black;
`;

const Title = styled.div`
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 0 20px 0;
  a {
    display: inline-flex;
    align-items: center;
    color: white;
  }
`;
