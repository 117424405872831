import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';
import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';
import translationData from '../../i18n/locales/pt-pt';
import Translator from '../../i18n/Translator';
import classNames from 'classnames';

const ManifestoComponent = () => {
  const manifesto = translationData.translations.manifesto;

  const { i18n } = useTranslation();

  const selectedLanguage = i18n.language;

  const portugueseSelected = selectedLanguage === 'pt-PT';

  return (
    <StyledGlobalWrapper rowSpacing={6}>
      <Grid item xs={12}>
        <h1 className={classNames('text-4xl')}>Manifesto</h1>
      </Grid>
      {Object.entries(manifesto).map(([key, manif], idx) => (
        <Grid key={key} item xs={12}>
          <h4>{portugueseSelected ? `REGRA #${idx + 1}` : `RULE #${idx + 1}`}</h4>
          <h4>
            <Translator path={`manifesto.${key}.title`} />
          </h4>

          {Object.keys(manif)
            .filter(k => k !== 'title')
            .map(k => (
              <p key={`${key}-${k}`}>
                <Translator path={`manifesto.${key}.${k}`} />
              </p>
            ))}
        </Grid>
      ))}
    </StyledGlobalWrapper>
  );
};

export default ManifestoComponent;

const HEADER_AND_FOOTER_HEIGHT = '415';

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    min-height: calc(100vh - ${HEADER_AND_FOOTER_HEIGHT}px);

    margin-top: 90px;
    margin-bottom: 50px;
  }

  p {
    font-weight: 200;
  }
`;
