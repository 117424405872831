export const partners = {
  pageTitle: 'Partners',
  tailfin: {
    description:
      'Technical Bikepacking Equipment.<br/>Racks and bags for the ultimate racing and bikepacking adventures or simple day-to-day commutes. Beautiful, lightweight and easy to use.',
  },
  bello: {
    description:
      'Bello Cyclist™ is one of the market leaders in production of custom, 100% cotton cycling caps. Caps with great quality and fully customizable.',
  },
  endoo: {
    description:
      'Endurance cycling podcast in Portuguese. The goal is to unite the community of cyclists by sharing experiences and knowledge.<br/>It is also a goal to create moments and events accessible to all (regardless of knowledge of long distances), to guarantee the good reception of all those who discover this aspect.',
  },
  aveo: {
    description:
      "Aveo, our valued race partner, is a leading brand specializing in cycling sunglasses. Dive into their collection of cutting-edge eyewear, where innovation meets style.<br/>With a dedicated focus on enhancing the cycling experience, Aveo's sunglasses combine performance and aesthetics, making them the ideal choice for riders seeking top-notch quality. Join us in embracing the thrill of the ride with Aveo, a brand that truly understands and elevates the essence of cycling.",
  },
  vandal: {
    description:
      'Discover the epitome of style and performance with The Vandal. Each piece is a testament to their dedication to the craft, ensuring that cyclists not only look their best but also perform at their peak. As our esteemed partner, The Vandal elevates the race experience, bringing a touch of luxury to every turn of the wheel. Join us in celebrating a partnership that embodies the fusion of passion, precision, and performance.',
  },
  devise: {
    description:
      'Its primary goal is to work on the developmet of new solutions for the industry, namely industrial machines as well as the development of innovative products.<br/>It operates across a multidisciplinary spectrum, encompassing mechanical engineering, CAD design, CNC machining, laser cutting, as well as software, automation, and robotics.',
  },
};
