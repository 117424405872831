import classNames from 'classnames';
import Translator from '../../../i18n/Translator';

const SuccessComponent = () => {
  return (
    <>
      <img
        className={classNames('w-full mt-32 md:mt-0')}
        src="/images/registration-success-banner.jpg"
        alt="the goats registration success banner"
      />
      <div className={classNames('max-w-[1200px] w-full mx-auto mb-32')}>
        <h1 className={classNames('text-4xl my-4')}>Let&apos;s goooo...!</h1>
        <p>
          <Translator path="registrations.form.registrationConfirmed" />
        </p>
      </div>
    </>
  );
};

export default SuccessComponent;
