export const partners = {
  pageTitle: 'Parceiros',
  tailfin: {
    description:
      'Equipamento Técnico para Bikepacking.<br/>Racks e bolsas para desafios de ultradistância e bikepacking ou simples deslocações diárias. Leve, visualmente único e fácil de usar.',
  },
  bello: {
    description:
      'Bello Cyclist™ é um dos líderes de mercado na produção de bonés de ciclismo personalizados 100% algodão. Bonés com ótima qualidade e totalmente personalizáveis.',
  },
  endoo: {
    description:
      'Podcast de ciclismo de endurance em portugues. O objetivo é unir a comunidade que pedala, através da partilha de experiências e conhecimento.<br/>É ainda objetivo a criação de momentos e eventos acessíveis a todos (independentemente do conhecimento sobre longas distâncias), garantindo a boa recepção de todos os que descobrem esta vertente.',
  },
  aveo: {
    description:
      'Aveo é uma marca líder especializada em óculos de sol para ciclismo. Explore a asue coleção de óculos de última geração, onde a inovação encontra o estilo. Com o foco dedicado em aprimorar a experiência de ciclismo, os óculos da Aveo combinam desempenho e estética, tornando-os a escolha ideal para ciclistas que buscam qualidade de alto nível. Aveo: uma marca que compreende e eleva verdadeiramente a essência do ciclismo.',
  },
  vandal: {
    description:
      'Descubra o epítome do estilo e do desempenho com o The Vandal. Cada peça é um testemunho da sua dedicação ao ofício, assegurando que os ciclistas não só estão no seu melhor, mas também têm um desempenho máximo. Como nosso estimado parceiro, a The Vandal eleva a experiência da corrida, trazendo um toque de luxo a cada volta da roda. Junte-se a nós para celebrar uma parceria que personifica a fusão de paixão, precisão e desempenho.',
  },
  devise: {
    description:
      'O seu principal objetivo é trabalhar no desenvolvimento de novas soluções para a indústria, nomeadamente máquinas industriais, bem como no desenvolvimento de produtos inovadores.<br/>Actua num espetro multidisciplinar, englobando engenharia mecânica, desenho CAD, maquinação CNC, corte laser, bem como software, automação e robótica.',
  },
};
