import classNames from 'classnames';
import PhotoAlbum from 'react-photo-album';

const LANDSCAPE_FORMAT = { width: '1600', height: '1067' };
const PORTRAIT_FORMAT = { width: '1067', height: '1600' };

const photos = [
  { src: '/images/photo-dump/dump1.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump2.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump3.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump4.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump5.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump6.jpg', ...PORTRAIT_FORMAT },
  { src: '/images/photo-dump/dump7.jpg', ...PORTRAIT_FORMAT },
  { src: '/images/photo-dump/dump8.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump9.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump10.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump11.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump12.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump13.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump14.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump15.jpg', ...PORTRAIT_FORMAT },
  { src: '/images/photo-dump/dump16.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump17.jpg', ...PORTRAIT_FORMAT },
  { src: '/images/photo-dump/dump18.jpg', ...PORTRAIT_FORMAT },
  { src: '/images/photo-dump/dump19.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump20.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump21.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump22.jpg', ...LANDSCAPE_FORMAT },
  { src: '/images/photo-dump/dump23.jpg', ...PORTRAIT_FORMAT },
];

const PhotoDumpComponent = () => {
  return (
    <div className={classNames('my-5')}>
      <h5 className={classNames('text-center italic')}>#2023EditionPhotoDump</h5>
      <div>
        <PhotoAlbum layout="columns" photos={photos} />
      </div>
    </div>
  );
};

export default PhotoDumpComponent;
