import { useLocation } from 'react-router-dom';

const useNavbar = () => {
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  return { isHomePage };
};

export default useNavbar;
