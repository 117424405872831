import { Grid } from '@mui/material';
import styled from 'styled-components';
import GlobalWrapper from '../../design-system/atoms/GlobalWrapper';
import translationData from '../../i18n/locales/pt-pt';
import Translator from '../../i18n/Translator';

const PrivacyPolicyComponent = () => {
  const privacyData = translationData.translations.privacyPolicy;

  return (
    <StyledGlobalWrapper rowSpacing={6}>
      <Grid item xs={12}>
        <h1>
          <Translator path="privacyPolicy.pageTitle" />
        </h1>
      </Grid>
      <Grid item xs={12}>
        {Object.keys(privacyData)
          .filter(key => key !== 'pageTitle')
          .map(key => {
            if (key.includes('question'))
              return (
                <h4>
                  <Translator path={`privacyPolicy.${key}`} />
                </h4>
              );

            return (
              <p key={key}>
                <Translator path={`privacyPolicy.${key}`} />
              </p>
            );
          })}
      </Grid>
    </StyledGlobalWrapper>
  );
};

export default PrivacyPolicyComponent;

const HEADER_AND_FOOTER_HEIGHT = '415';

const StyledGlobalWrapper = styled(GlobalWrapper)`
  &&&& {
    min-height: calc(100vh - ${HEADER_AND_FOOTER_HEIGHT}px);
    margin-top: 90px;
    margin-bottom: 50px;
  }

  p {
    font-weight: 200;
  }
`;
